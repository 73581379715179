import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"

import { DialogContentText } from "@mui/material"
import { Transition } from "../../../animations/Transition"

interface ConfirmDeleteBotModalProps
{
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDeleteBotModal = ({ onClose, open, onConfirm }: ConfirmDeleteBotModalProps) => {

  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullWidth maxWidth="xs">

      <DialogTitle>Confirm Grid Bot Delete [{botConfig?.tradingPair}]</DialogTitle>

     <DialogContent>

      <DialogContentText>Are you sure you want to delete this bot? You should make sure you have stopped this bot first to ensure all limit orders have been cancelled.</DialogContentText>

     </DialogContent>

     <DialogActions>

      <Button color="secondary" onClick={onClose} startIcon={<CloseIcon />}>Cancel</Button>
      <Box flex={1} />
      <Button color="secondary" onClick={onConfirm} startIcon={<CheckIcon />} >Yes, Delete This Bot</Button>

     </DialogActions>


    </Dialog>
    
  )
}
