import { useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import { BotConfigItem } from "../list/BotConfigItem"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"


export const BotHistory = () => {

  const configs = useSelector((state: ApplicationState) => state.grid.botConfigs)

  return (
    <Grid container flex={1} sx={{ mt: 1 }}>
      
   <Grid item flex={1} style={{ height: "30vh", overflow: "auto" }}>

   <Card >

    { configs.filter(config => !config.isEnabled).map((config, key) => <BotConfigItem item={config} key={key}/>)}

    </Card>

    </Grid>   
 
  </Grid>)
}