import TableContainer from "@mui/material/TableContainer"
import { CreateBuyResult } from "../../../interface/CreateBuyResult"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import CheckOutlined from "@mui/icons-material/CheckOutlined"
import ErrorOutline from "@mui/icons-material/ErrorOutline"


interface CreateBuyResultsTableProps
{
  buyResults: CreateBuyResult[]
}

export const CreateBuyResultsTable = ({ buyResults }: CreateBuyResultsTableProps) => {
  return (<TableContainer style={{ overflowY: "auto", height: "70vh"}} component={Paper} >
    <Table aria-label="simple table" stickyHeader >
      <TableHead>
        <TableRow>
          <TableCell align="right">Success</TableCell>
          <TableCell align="right">Buy Result</TableCell>
          <TableCell align="right">Buy Price</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        {buyResults?.map((row, key) => (
          <TableRow
            hover
            key={key}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

          <TableCell align="right">{row.success ? <CheckOutlined color="success" /> : <ErrorOutline color="error" />}</TableCell>
          <TableCell align="right">{row.success ? "Successfully placed order at grid level" : row.error}</TableCell>
          <TableCell align="right">${row.price}</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>)
}