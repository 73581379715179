import Grid from "@mui/material/Grid"
import { SearchBar } from "../../components/SearchBar"
import { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { BackTestApi } from "../../api/BackTestApi"
import { BacktestStore, setBacktestSettings } from "../../redux/reducers/backtesting/backtestingReducer"
import { BackTestingTable } from "./BackTestingTable"
import { useDispatch } from "react-redux"
import { EditCustomBacktest } from "../../components/modals/CustomBacktest/EditCustomBacktest"
import { LottieLoading } from "../../components/placeholder/LottieLoading"
import { CreateCustomBackTest } from "../../components/modals/CustomBacktest/CreateCustomBackTest"
import AddIcon from '@mui/icons-material/Add'

interface CustomBacktestingProps
{
  isAdmin?: boolean
}

export const CustomBacktesting = ({ isAdmin }: CustomBacktestingProps) => {

  const [ query, setQuery ] = useState("")
  const [ showAddtest, setShowAddTest ] = useState(false)
  const [ showEditTest, setShowEditTest ] = useState(false)
  const [ gettingTests, setGettingTests ] = useState(false)

  const [ tests, setTests ] = useState<BacktestStore[]>([])
  const dispatch = useDispatch()

  useEffect(() => {

    if(isAdmin) getAllUserBacktestConfigs(true)
    if(!isAdmin) getBacktests(true)

  }, [])

  const getBacktests = async (progress: boolean) => {

    if(progress) setGettingTests(true)
    try
    {
      const response = await new BackTestApi().getCustomTests()
      if(response) setTests(response)
    }
    catch(error)
    {

    }

    if(progress) setGettingTests(false)

  }

  const getAllUserBacktestConfigs = async (progress: boolean) => {

    if(progress) setGettingTests(true)
    try
    {
      const response = await new BackTestApi().getAllForUsers()
      if(response) setTests(response)
    }
    catch(error)
    {

    }

    if(progress) setGettingTests(false)

  }

  const handleEditTest = (item: BacktestStore) => {
    setShowEditTest(true)
    dispatch(setBacktestSettings(item))
  }

  const handleFinishEdit = async () => {
    setShowEditTest(false)
    if(!isAdmin) getBacktests(true)
  }

  const handleFinishAdd = async () => {
    setShowAddTest(false)
    await getBacktests(false)
  }

  const useSearchFilter = () => {
    if(!query) return tests;
    return tests?.filter(test => {
      if(test?.username?.toLowerCase().includes(query?.toLowerCase())) return true
      if(test?.friendlyName?.toLowerCase().includes(query?.toLowerCase())) return true
    })
  }

  const filteredTests = useSearchFilter()

  return (<Grid container direction="column" >

    {/* Controls */}
    <Grid item container alignItems="center" spacing={3}>
      <Grid item xs={10}>
        <SearchBar placeholder="Search" setQuery={setQuery} />
      </Grid>
      <Grid item xs={2}>
        <Button style={styles.addButton} startIcon={<AddIcon />} onClick={() => setShowAddTest(true)} fullWidth size="large" variant="contained">Add new test</Button>
      </Grid>
    </Grid>

    {/* Table */}
    { gettingTests && <LottieLoading height={150} width={150} animation={require("../../animations/backtesting-lottie.json")} containerHeight="80vh" loop text="Getting Custom Backtests" /> }
    { !gettingTests && filteredTests.length === 0 && <LottieLoading height={150} width={150} animation={require("../../animations/not-found-lottie.json")} containerHeight="80vh" loop={false} text={query ? "No tests found for that search" : "Looks like you've got no custom backtests. Add one to get started."} /> }
    { !gettingTests && filteredTests.length > 0 && <BackTestingTable results={filteredTests} onOpenItem={handleEditTest} isAdmin={isAdmin} /> }
    

   {/* Modals */}
   { showAddtest && <CreateCustomBackTest open onClose={handleFinishAdd}/>}
   { showEditTest && <EditCustomBacktest open onClose={handleFinishEdit} />}

  </Grid>)
}

const styles = {
  addButton: {
    height: 55
  }
}