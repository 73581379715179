import Grid from "@mui/material/Grid"
import { LogApi } from "../../api/LogApi"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { useEffect, useState } from "react"
import { ProfitLoss } from "../../interface/ProfitLoss"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import { Avatar, LinearProgress, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material"
import { Balance, MonetizationOn, Money, Scale, Timer } from "@mui/icons-material"
import moment from "moment"
import { amber, blue, green, orange, pink, purple, yellow } from "@mui/material/colors"



export const ProfitAndLoss = () => {

  const tradingPair = useSelector((state: ApplicationState) => state.userStore.user.tradingPair)
  const [ cryptoAmount, setCryptoAmount ] = useState("")
  const [ loading, setLoading ] = useState(false)

  const [ summary, setSummary ] = useState<undefined | ProfitLoss>(undefined)

  useEffect(() => {

    getProfits()

  }, [tradingPair, cryptoAmount])

  const getProfits = async () => {
    setLoading(true)
    try
    {
      if(!tradingPair) return
      const response = await new LogApi().profitLoss(tradingPair, Number(cryptoAmount))
      setSummary(response)
    }
    catch(error)
    {

    }

    setLoading(false)
  }

  const getProfitPercent = (hold?: number, bot?: number) => {

    if(!hold) return 0
    if(!bot) return 0
    return ((bot - hold) / hold * 100 ).toFixed(2)
  }

  return (
    <>
    <Typography fontWeight={700} sx={{ mb: 0.1 }}>Trading vs HODLing strategy for trading pair profit analysis [{tradingPair}]</Typography>
    <Typography color="textSecondary" fontSize={14} sx={{ mb: 0.5 }}>Please not that these calculations will only be accurate if you have not added or withdrawn assets
      or changed the initial crypto amount (e.g. increased your max invest)
    </Typography>

    <Card style={styles.container}>
      
    { loading ? <LinearProgress color="info" style={{ height: 4 }} /> : <div style={{ height: 4 }} />}

    <Grid container >

    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

    <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: yellow[500]}}><Balance /></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Initial Balance: ${summary?.initialCryptoValue || 0}`} secondary="The initial balance of crypto you started with on your first trade"/>
      </ListItem>

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: blue[500]}}>{summary?.numberOfTrades || 0}</Avatar>
        </ListItemAvatar>
        <ListItemText primary="Trades" secondary="The number of trades executed by the bot for this pair"/>
      </ListItem>


      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: pink[400]}}><Timer /></Avatar>
        </ListItemAvatar>
        <ListItemText primary="Duration" secondary={`First trade: ${moment(summary?.firstTrade).format("LLLL")} - ${moment(summary?.firstTrade).fromNow()}`} />
      </ListItem>

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: purple[400]}}><Timer /></Avatar>
        </ListItemAvatar>
        <ListItemText primary="Duration" secondary={`Most recent trade: ${moment(summary?.lastTrade).format("LLLL")} - ${moment(summary?.lastTrade).fromNow()}`} />
      </ListItem>
      
    </Grid>


    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

    <ListItem secondaryAction={<TextField placeholder="Current amount" value={cryptoAmount} onChange={(e: any) => setCryptoAmount(e.target.value)} />}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: green[500]}}><Balance /></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Current Balance: ${cryptoAmount || summary?.cryptoCurrentValue}`} secondary="The current balance of crypto you have now (if this is 0, bot has sold to base pair) "/>

      </ListItem>

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: amber[500]}}>{<Money />}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Profit if HODLing: $${summary?.profits.profitHold || 0} USD`} secondary="The profit you would have attained by holding the asset and not trading"/>
      </ListItem>


      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: yellow[500]}}><MonetizationOn /></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Profit if trading: $${summary?.profits.profitBot || 0} USD`} secondary="The profit you have attained by trading this pair"/>
      </ListItem>

      <ListItem>

        <ListItemAvatar>
          <Avatar style={{ backgroundColor: orange[500]}}><Scale /></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Comparison: $${summary?.profits.comparison || 0} USD (${getProfitPercent(summary?.profits.profitHold, summary?.profits.profitBot)}%)`} secondary="The difference between HODLing and trading"/>
      </ListItem>

      
    </Grid>

      

      
    </Grid>

    </Card>
    </>
  )
}

const styles = {
  container: { 
    paddingBottom: 32,
  }
}