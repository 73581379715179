import Grid from '@mui/material/Grid'
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import Slider from '@mui/material/Slider';
import TimerIcon from '@mui/icons-material/Timer';

import OutlinedInput from '@mui/material/OutlinedInput';
import PairIcon from '@mui/icons-material/DoubleArrow'

import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import { DropdownSelector } from '../../components/DropdownSelector';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { getSymbolsBegin, updateLocalUser } from '../../redux/actions/userActions';
import { styles } from '../Settings';
import { Button, Typography, useTheme } from '@mui/material';
import { DEFAULT_FUNDING_CURRENCY, MAX_MULTI_PAIRS } from '../../const';
import { useEffect } from 'react';
import { TextWithIcon } from '../../components/TextWithIcon';
import { MultiPairSection } from './MultiPairSection';
import { v4 as uuid } from 'uuid';
import { TradingPair } from '../../interface/TradingPair';


export const TradeSettings = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const symbols = useSelector((state: ApplicationState) => state.userStore.symbols)
  const isGettingSymbols = useSelector((state: ApplicationState) => state.userStore.isGettingSymbols)


  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  useEffect(() => {

    if(symbols.length === 0) dispatch(getSymbolsBegin())

  }, [])



  const handleAddNewPair = () =>
  {
    const newPair: TradingPair = {
      pairId: uuid(), 
      isEnabled: true, 
      base: "", 
      currentInvest: 0, 
      maxInvest: 100, 
      pair: "", 
      interval: user.chartInterval || "1m",
      useDynamicStop: false,
      stopLossPercentage: 0,
      isStopLossEnabled: false
    }
    
    const allPairs = user.tradingPairs?.concat(newPair)
    dispatch(updateLocalUser({ tradingPairs: allPairs }))
  }

  const isMax = () =>
  {
    if(!user.tradingPairs) return true
    if(user?.tradingPairs.length >= MAX_MULTI_PAIRS) return true;
    return false
  }

  const isMaxLimit = isMax()

  return(
  <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>

    <Grid item>
      <FormControlLabel
          control={<Switch checked={user.tradingEnabled} onChange={(e) => dispatch(updateLocalUser({ tradingEnabled: e.target.checked }))} name="Trading Enabled" />}
          label="Trading Enabled (Switching off pauses trading for your bot)" />
    </Grid>

    <Grid item>
      <FormControlLabel
          control={<Switch checked={user.preferMarket} onChange={(e) => dispatch(updateLocalUser({ preferMarket: e.target.checked }))} name="Prefer Market Order" />}
          label="Prefer Market - use market orders over limit." />
    </Grid>

    <Grid item>
      <FormControlLabel
          control={<Switch checked={user.isBuyBackInDelayEnabled} onChange={(e) => dispatch(updateLocalUser({ isBuyBackInDelayEnabled: e.target.checked }))} name="Buy back delay" />}
          label="Enable buy back in delay. Once bot sells, bot won't buy again until after delay (Good for EMA on short candles e.g 1m)" />
    </Grid>

    { user.isBuyBackInDelayEnabled && <Grid item>
        <TextWithIcon icon={<TimerIcon sx={{ mr: 1, color: "grey" }} />} text="Buy back in delay (in minutes)" style={{ marginTop: 12 }} />
          <Slider
              onChange={(e: any) => dispatch(updateLocalUser({ buyBackInDelay: e.target.value }))}
              style={styles.slider}
              aria-label="buy back in delay slider"
              defaultValue={15}
              value={user.buyBackInDelay}
              step={1}
              marks
              min={1}
              max={60}
              valueLabelDisplay="on"
            />
      </Grid> }

    <Grid item>
      <FormControlLabel
          control={<Switch checked={user?.isMultiTradingEnabled} onChange={(e) => dispatch(updateLocalUser({ isMultiTradingEnabled: e.target.checked }))} name="Multi Pair" />}
          label="Enable Multi Pair Trading" />
      </Grid>

    <div style={styles.spacer} />

    {/* Single Pair Setup */}
    { !user.isMultiTradingEnabled && <Grid item container spacing={3}>

    <Grid item xl={3} lg={6} md={12} xs={12}>
        <FormLabel component="legend" style={styles.formLabel}>The maximum the bot will buy at any time</FormLabel>
        <OutlinedInput startAdornment={<InputAdornment position="start">$</InputAdornment>} value={user.maxBuy} inputMode="numeric" fullWidth placeholder="Max Buy" onChange={(e: any) => dispatch(updateLocalUser({ maxBuy: e.target.value })) } />
      </Grid>

      <Grid item xl={3} lg={6} md={12} xs={12}>
        <FormLabel component="legend" style={styles.formLabel}>The maximum the bot will sell at any time</FormLabel>
        <OutlinedInput startAdornment={<InputAdornment position="start">$</InputAdornment>} value={user.maxSell} inputMode="numeric" fullWidth placeholder="Max Sell" onChange={(e: any) => dispatch(updateLocalUser({ maxSell: e.target.value })) }/>
      </Grid>

      <Grid item xl={3} lg={6} md={12} xs={12}>
        { isGettingSymbols ? <Skeleton height={100} /> : <DropdownSelector currentItem={user.tradingPair} onChangeItem={(pair) => dispatch(updateLocalUser({ tradingPair: pair }))} title="The Binance trading pair" items={symbols.map(item => item.symbol)} /> }
      </Grid>

      <Grid item xl={3} lg={6} md={12} xs={12}>

        <DropdownSelector 
          currentItem={user.fundingCurrency} 
          onChangeItem={(value) => dispatch(updateLocalUser({ fundingCurrency: value }))}
          title="The funding currency the bot will use to buy"
          items={[DEFAULT_FUNDING_CURRENCY, "USDT", "BUSD" ]}/>
        </Grid>

      </Grid> }
    
    { user.isMultiTradingEnabled && <Grid container alignItems='center' sx={{ mb: 2 }}>

      <Grid item>
      <Typography fontWeight="700" fontSize={16} variant="h5">Trading Pair List</Typography>
      </Grid>

      <Grid item flex={1}>

      </Grid>

      <Grid item>
        <Button disabled={isMaxLimit} onClick={handleAddNewPair} startIcon={<PairIcon />}>{ isMaxLimit ? `Max ${MAX_MULTI_PAIRS} Pairs` : "Add New Trading Pair"}</Button>
      </Grid>
      
      </Grid>}
    
    { user.isMultiTradingEnabled && user?.tradingPairs?.map((tp, key) => <MultiPairSection loading={isGettingSymbols} symbols={symbols} key={key} tradingPair={tp} />)} 
   
    </Card>
  )
}