import Grid from "@mui/material/Grid"
import { MarketList } from "./MarketList"
import { BotList } from "./BotList"
import { GridOrders } from "./tabs/GridOrders"
import { TabSection } from "./TabSection"
import { useEffect, useRef, useState } from "react"
import { GridChartSection } from "./GridChartSection"
import { useDispatch } from "react-redux"
import { getBotConfigs } from "../../redux/reducers/grid/gridReducer"
import { ProfitSummary } from "./tabs/ProfitSummary"
import { BotHistory } from "./tabs/BotHistory"


export const GridBotSection = () => {

  const [ tab, setTab ] = useState(0)
  const dispatch = useDispatch()
  const ref = useRef<NodeJS.Timer>()

  useEffect(() => {

    ref.current = setInterval(() => {

      dispatch(getBotConfigs())

    }, 60000 * 5)

    return () => {
      clearInterval(ref.current)
    }

  }, [])

  return (
    <Grid container direction="column" spacing={1} >

      {/* First Row */}
      <Grid item container direction="row" spacing={3}>

      {/* Markets */}
      <MarketList />

      {/* Chart */}
      <GridChartSection />

      {/* Bots */}
      <BotList />

      </Grid>

      {/* // Tab headers */}
      <Grid item>

      <TabSection tab={tab} setTab={setTab} />

      </Grid>

      {/* Tab sections */}
      { tab === 0 && <GridOrders /> }
      { tab === 1 && <ProfitSummary /> }
      { tab === 2 && <BotHistory /> }

      
    </Grid>
  )
}