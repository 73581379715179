import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import { styles } from '../Pages/Settings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface DropdownSelectorProps
{
  items: string[];
  currentItem: string | undefined
  onChangeItem: (item: string) => void
  title: string
}

export const DropdownSelector = ({ currentItem, onChangeItem, title, items }: DropdownSelectorProps) =>
{
  return(
    <>
      <FormLabel component="legend" style={styles.formLabel}>{title}</FormLabel>
      <FormControl fullWidth>
      <Autocomplete
        value={{ label: currentItem || "" }}
        renderInput={(params) => <TextField {...params} label={title} />}
        id="demo-simple-select"
        onChange={(_, value) => onChangeItem(value?.label as string)}
        options={items.map(item => ({ label: item }))}
        />
    </FormControl>
  </>
  )
}