import Typography from "@mui/material/Typography"
import { GREEN, RED } from "../../Theme/theme"
import Badge from "@mui/material/Badge"


interface GenericTabletProps
{
  text: string
  color: string
}

export const GenericTablet = ({ text, color }: GenericTabletProps) =>
{
  return(
    <div style={{ borderRadius: 12, backgroundColor: color, padding: 2, textAlign: 'center' }}>
    <Typography fontWeight={700} fontSize={12} variant="subtitle2">{text}</Typography>
    </div>
  )
}