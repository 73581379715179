import { CreateBuyResult } from "../interface/CreateBuyResult";
import { IGridBotConfig } from "../interface/GridBotConfig";
import { MarketData } from "../interface/MarketData";
import { QueryOrderResult } from "../interface/QueryOrderResult";
import { HttpService } from "./HttpService";


export class GridApi extends HttpService
{
  create = async (config: IGridBotConfig) =>
  {
    const response = await this.request<{ gridConfig: IGridBotConfig, buyResults: CreateBuyResult[] }>("POST", "grid/create", config)
    if(response) return response.data
  }

  /**
   * @description Syncs the binance limit orders with the local data
   * @returns result
   */
  refreshStatus = async () =>
  {
    const response = await this.request<{ result: string}>("POST", "grid/refreshStatus", null)
    if(response) return response.data
  }

  getConfigs = async (): Promise<IGridBotConfig[] | undefined> =>
  {
    const response = await this.request<IGridBotConfig[]>("GET", "grid/config", null)
    if(response) return response.data
  }

  getMarketList = async (): Promise<MarketData[] | undefined> =>
  {
    const response = await this.request<MarketData[]>("GET", "grid/marketList", null)
    if(response) return response.data
  }

  getLiveOrdersForBot = async (botId: string): Promise<{ sellOrders: QueryOrderResult[], buyOrders: QueryOrderResult[] } | undefined> =>
  {
    const response = await this.request<{ sellOrders: QueryOrderResult[], buyOrders: QueryOrderResult[] }>("GET", `grid/status?botId=${botId}`, null)
    if(response) return response.data
  }

  /**
   * @description Stops a bot, disables the isEnabled property, removes all the buy and limit orders placed.
   * @param botId 
   * @returns 
   */
  stopBot = async (botId: string): Promise<{ result: string } | undefined> =>
  {
    const response = await this.request<{ result: string }>("POST", `grid/stop?botId=${botId}`, null)
    if(response) return response.data
  }
  
  /**
   * @description Deletes a bot - should be called after stop bot otherwise all the limit orders will remain
   * @param botId
   * @returns result
   */
  remove = async (botId: string): Promise<{ result: string } | undefined> =>
    {
      const response = await this.request<{ result: string }>("DELETE", `grid/remove?botId=${botId}`, null)
      if(response) return response.data
    }

}