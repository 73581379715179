import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import FormLabel from "@mui/material/FormLabel"
import Grid from "@mui/material/Grid"
import OutlinedInput from "@mui/material/OutlinedInput"
import { DropdownSelector } from "../../components/DropdownSelector"
import InputAdornment from "@mui/material/InputAdornment"
import { DEFAULT_FUNDING_CURRENCY, getAllTypes } from "../../const"
import Skeleton from "@mui/material/Skeleton"
import { TradingPair } from "../../interface/TradingPair"
import { updateLocalUser } from "../../redux/actions/userActions"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import RefreshIcon from "@mui/icons-material/RestartAlt"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Divider from "@mui/material/Divider"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { Symbol } from "../../interface/Symbol"


interface MultiPairSectionProps
{
  symbols: Symbol[]
  tradingPair: TradingPair
  loading: boolean
}

export const MultiPairSection = ({ symbols, tradingPair, loading }: MultiPairSectionProps) =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)

  const handleRemove = (id: string) =>
  {
    if(!user.tradingPairs) return
    dispatch(updateLocalUser({ tradingPairs: user.tradingPairs.filter(p => p.pairId !== id)}))
  }

  const handleUpdatePair = (updatedPair: Partial<TradingPair>) =>
  {
    if(!user.tradingPairs) return
    const updatedPairs = user.tradingPairs.map((pair) => {
      if(pair.pairId === tradingPair.pairId) return { ...tradingPair, ...updatedPair, base: updatedPair?.pair?.slice(-4) || pair.base }
      return pair
    })

    dispatch(updateLocalUser({ tradingPairs: updatedPairs }))
  }

  return(

  <Card style={styles.card}>
    <Typography variant="h6" fontWeight="700">{tradingPair.pair}</Typography>
    <Divider sx={{ mb: 1 }} />

  <Grid container spacing={3} alignItems="center" sx={{ mb: 2 }}>

  <Grid item xl={2} lg={6} md={12} xs={12}>
      <FormLabel component="legend" style={styles.formLabel}>Current investment</FormLabel>
      <OutlinedInput 
        disabled
        startAdornment={<InputAdornment position="start">$</InputAdornment>} 
        endAdornment={<IconButton onClick={() => handleUpdatePair({ currentInvest: 0 })}><RefreshIcon /></IconButton>} 
        value={tradingPair.currentInvest} inputMode="numeric" fullWidth />
    </Grid>

  <Grid item xl={2} lg={6} md={12} xs={12}>
      <FormLabel component="legend" style={styles.formLabel}>Max invest for this pair</FormLabel>
      <OutlinedInput startAdornment={<InputAdornment position="start">$</InputAdornment>} value={tradingPair.maxInvest} inputMode="numeric" fullWidth placeholder="Max Invest" onChange={(e: any) => handleUpdatePair({ maxInvest: e.target.value }) } />
  </Grid>

    <Grid item xl={2} lg={6} md={12} xs={12}>
      { loading ? <Skeleton height={100} /> : <DropdownSelector currentItem={tradingPair.pair} onChangeItem={(pair) => handleUpdatePair({ pair })} title="The Binance trading pair" items={symbols.map(item => item.symbol)} /> }
    </Grid>

    <Grid item xl={3} lg={3} md={12} xs={12}>

      <DropdownSelector 
        currentItem={tradingPair.base} 
        onChangeItem={(value) => handleUpdatePair({ base: value })}
        title="The funding currency the bot will use to buy"
        items={[DEFAULT_FUNDING_CURRENCY, "USDT", "BUSD" ]}/>
      </Grid>

      <Grid item xl={2} lg={2} md={12} xs={12}>

        <DropdownSelector 
          currentItem={tradingPair.interval} 
          onChangeItem={(value) => handleUpdatePair({ interval: value })}
          title="Candle Interval"
          items={getAllTypes()}/>
      </Grid>

    <Grid item xl={1} lg={1} md={12} xs={12} sx={{ mt: 4 }}>
      <Button variant="text" startIcon={<DeleteIcon />} onClick={() => handleRemove(tradingPair.pairId)}>Delete</Button>
    </Grid>

    </Grid>

    <Grid item container direction="column"> 
      <Typography variant="subtitle1" fontWeight="700">Stop loss configuration</Typography>
      <Typography variant="caption" color="textSecondary">Dynamic stop loss is calculated by taking the markets daily stats low and high to produce a suitable loss percentage</Typography>

      <FormControlLabel label="Enable stop" control={<Switch checked={tradingPair.isStopLossEnabled} onChange={(_: any, checked: boolean) => handleUpdatePair({ isStopLossEnabled: checked })} />} />
      { tradingPair.isStopLossEnabled && <FormControlLabel sx={{ mb: 1 }} label={`Use dynamic stop ${tradingPair.stopLossPercentage}%`} control={<Switch checked={tradingPair.useDynamicStop} onChange={(_: any, checked: boolean) => handleUpdatePair({ useDynamicStop: checked })} />} /> }
      { tradingPair.isStopLossEnabled && !tradingPair.useDynamicStop && <Grid item><TextField label="Stop Loss" type="number" value={tradingPair.stopLossPercentage} placeholder="Stop Percentage" onChange={(e: any) => handleUpdatePair({ stopLossPercentage: e.target.value })} InputProps={{ endAdornment: "%"}}/> </Grid>}
    </Grid> 
  
    </Card>
  )
}

const styles = {

  formLabel: {
    marginBottom: 12
  },
  card: {
    marginBottom: 12,
    padding: 12,
  }
}