import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import { BuyTable } from "../table/BuyTable"
import { SellTable } from "../table/SellTable"


export const GridOrders = () => {

  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)

  const sortedBuyOrderHistory = [...(botConfig?.buyOrderHistory || [])].sort(
    (a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf()
  );

  const sortedSellOrderHistory = [...(botConfig?.sellOrderHistory || [])].sort(
    (a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf()
  );


  return (
    <Grid item container spacing={3}>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Typography sx={{ m: 1 }} fontWeight={800} textAlign="center">Buy Orders</Typography>
        <Card style={styles.cardContainer}> 
        <Divider />
        <BuyTable orders={sortedBuyOrderHistory || []} />
        </Card>
      </Grid>
  
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Typography sx={{ m: 1 }} fontWeight={800} textAlign="center">Sell Orders</Typography>
        <Card style={styles.cardContainer}> 
        <Divider />
        <SellTable orders={sortedSellOrderHistory || []} />

        </Card>
      </Grid>

    </Grid>
  )
}

const styles = {
  cardContainer: {
    borderRadius: 12,
    height: "35vh",
  }
}