import { ProfitChart } from "../components/charts/ProfitChart";
import CssBaseline from '@mui/material/CssBaseline'
import { useEffect, useState } from 'react';
import { IRunEvent } from '../interface/IRunEvent';
import moment from 'moment'
import { LogApi } from '../api/LogApi';
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { LogTimeRange } from "../components/LogTimeRange";
import { ProfitAndLoss } from "./profit/ProfitAndLoss";

export const Profit = () =>
{

  const [ logs, setLogs ] = useState<IRunEvent[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ order, setOrder ] = useState<"asc" | "desc">("desc")
  const [ limit, setLimit ] = useState(10000)
  const [ skip, setSkip ] = useState(0)
  const [ fromTime, setFromTime ] = useState(48)


  const getLogs = async () =>
  {

    setLoading(true)
    const logApi = new LogApi()
    const logData = await logApi.getRunEvents(order, limit, skip, moment().subtract(fromTime, "hours").toISOString());
    setLogs(logData)
    setLoading(false)
  }

  useEffect(() => {

    getLogs()

  }, [ fromTime ])

  return(
    <div style={styles.container}>
  
    <CssBaseline />

    <ProfitAndLoss />
          


    <Typography fontWeight={700} sx={{ mt: 2, mb: 0.5 }}>Total Assets Over Time</Typography>

     { loading ? <Skeleton height={500} /> : <ProfitChart runEvents={logs}/>} 

     <LogTimeRange setFromTime={setFromTime} fromTime={fromTime} />


    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    height: "90vh",
    flexDirection: "column" as "column",
  },
  
}