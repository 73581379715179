export const splitTradingPair = (pair: string) =>  {

  if(!pair) return { coin: undefined, base: undefined };
  const baseCurrencies = ['USDT', 'TUSD', 'BUSD', "FDUSD", "USDC" ];
  
  // Check each possible base currency and see if the string ends with it
  for (let base of baseCurrencies) {
    if (pair.endsWith(base)) {
      const coin = pair.slice(0, -base.length); // Everything before the base is the coin
      return { coin, base };
    }
  }
  
  // If no base currency is found, return null or handle the error
  return { coin: undefined, base: undefined };
}