import { Candle } from "../../../interface/Candle";

// Importing necessary modules
export const calculateGridRange = (candleData: Candle[]) => {

  if (!Array.isArray(candleData) || candleData.length === 0) throw new Error("Candle data must be a non-empty array.");

  // Calculate the highest and lowest price from the candle data
  const highPrices = candleData.map(candle => Number(candle.high));
  const lowPrices = candleData.map(candle => Number(candle.low));

  const highestPrice = Math.max(...highPrices);
  const lowestPrice = Math.min(...lowPrices);

  // Calculate the grid range
  const gridRange = {
      minPrice: lowestPrice,
      maxPrice: highestPrice,
  };

  return gridRange;
};

