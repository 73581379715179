import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { EMASMA } from '../../interface/EMASMA';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Custom } from '../../interface/Custom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { contentStyle, itemStyle } from '../../Theme/styles/chartTooltip';

interface CustomChartProps
{
  chartInterval: string;
  tradingPair: string;
  short: number;
  medium: number;
  long: number;
}


export const CustomChart = ({ chartInterval, tradingPair, short, medium, long }: CustomChartProps) =>
{

  const [ customData, setCustom ] = useState<Custom[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const rsiThresholdBuy = useSelector((state: ApplicationState) => state.userStore.user.rsiThresholdBuy)
  const rsiThresholdSell = useSelector((state: ApplicationState) => state.userStore.user.rsiThresholdSell)
  const adxTrendLimit = useSelector((state: ApplicationState) => state.userStore.user.adxTrendLimit)


  useEffect(() =>
  {
    getCustom(true)
  }, [])

  useEffect(() =>
  {
    getCustom(false)
  }, [chartInterval])

  const getCustom = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const customResult = await logApi.getCustom(tradingPair, chartInterval, short, medium, long)
      setCustom(customResult)
      load && setLoading(false)
    }

  }

  return(
  !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Custom Strategy Crossover Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={customData || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={['dataMin', 'dataMax']} /> 
        <YAxis yAxisId="right" domain={['dataMin - 1', 'dataMax + 1']} orientation="right" />

        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />

        <Legend formatter={(e) => {
          if(e === "shortEma") return `Short EMA Period (${short})`
          if(e === "longEma") return `Medium EMA Period (${medium})`
          if(e === "longEma") return `Long EMA Period (${long})`
          if(e === "rsi") return "RSI"
          if(e === "close") return "Close Price"
          if(e === "adx") return "ADX"


        }} />

        <Line yAxisId="left" type="monotone" dataKey="shortEma" stroke="#42A5F5" dot={false} strokeWidth={1} />
        <Line yAxisId="left" type="monotone" dataKey="mediumEma" stroke="#009688" dot={false} strokeWidth={2} />
        <Line yAxisId="left" type="monotone" dataKey="longEma" stroke="#FDD835" dot={false} strokeWidth={2} />
        <Line yAxisId="left" type="monotone" dataKey="close" stroke="#F06292" dot={false} strokeWidth={1} />
        <Line yAxisId="right" type="monotone" dataKey="rsi" stroke="#66BB6A" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="adx" stroke="#9E9E9E" dot={false} strokeWidth={2} />


        <Tooltip formatter={(tick, v) => {
          if(v === "rsi" && rsiThresholdBuy && rsiThresholdSell)
          {
            if(Number(tick) <= rsiThresholdBuy) return `${tick} (BUY)`
            if(Number(tick) >= rsiThresholdSell) return `${tick} (SELL)`
            return `${tick} (HOLD)`
          }
            
          if(v === "adx" && adxTrendLimit)
          {
            return `${tick} (${Number(tick) > adxTrendLimit ? "Trending" : "Flat"})`;
          }

          return (`$${Number(tick).toFixed(2)} USDT`)
        }} 
        labelStyle={{ color: "grey"}} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        contentStyle={contentStyle}
        itemStyle={itemStyle}
        />

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}