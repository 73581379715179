import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, Label, Area } from 'recharts';
import moment from 'moment'
import { Card } from '@mui/material';
import { IRunEvent } from '../../interface/IRunEvent';

interface ProfitChartProps
{
  runEvents: IRunEvent[] | undefined
}


export const ProfitChart = ({ runEvents }: ProfitChartProps) =>
{

  return(
    <Card style={styles.container}>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={300}
        data={runEvents?.map(i => i).reverse() || []} // Graph needs ASC so we reverse the results
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={200} />


        <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`}>
          <Label angle={-90} value='Amounts' position='insideLeft' style={{ textAnchor: 'middle', fill: "grey", fontSize: 12 }} />
        </YAxis>

        <YAxis yAxisId="right" tickFormatter={(tick) => `${tick}`} orientation="right">
          <Label angle={-90} value='Asset Qty' position='outside' style={{ textAnchor: 'middle', fill: "grey" }} />
        </YAxis>

        <Legend formatter={(key)=> {
          if(key === "totalAssets") return "Total Assets"

        }}/>
        <Area yAxisId="left" type="monotone" dataKey="totalAssets"  dot strokeWidth={3} />

        <Tooltip 
          labelStyle={{ color: "grey"}}
          formatter={(tick) => `$${Number(tick).toFixed(2)} USD`}  
          labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </Card>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 16,
  }
}