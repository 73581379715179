import { spawn } from 'redux-saga/effects'
import { billingSaga } from "./sagas/billingSaga";
import { userSaga } from "./sagas/userSaga";
import { chatSaga } from './sagas/chatSaga';
import { gridSaga } from './sagas/gridSaga';


export function* rootSaga()
{
  yield spawn(billingSaga)
  yield spawn(userSaga)
  yield spawn(chatSaga)
  yield spawn(gridSaga)

}