
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MACDSlider } from './MACDSlider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { EmaChooser } from './EmaChooser';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../interface/ApplicationState';
import { updateLocalUser as updateLocalUserData } from '../redux/actions/userActions';
import { styles } from '../Pages/Settings';
import { getSliderColor } from '../utils/getSliderColor';
import { TripleRsiCandles } from './TripleRsiCandles';
import { setBacktestConfig } from '../redux/reducers/backtesting/backtestingReducer';

interface IndicatorsProps
{
  isBacktest?: boolean
}

export const Indicators = ({ isBacktest }: IndicatorsProps) =>
{

  const updateLocalUser = isBacktest ? setBacktestConfig : updateLocalUserData
  const user = isBacktest ? useSelector((state: ApplicationState) => state.backtesting.config!!) : useSelector((state: ApplicationState) => state.userStore.user)
  const dispatch = useDispatch()


  return(
    <div>
      <Typography variant="h6" color="textSecondary" style={styles.heading}>Technical Indicators Section</Typography>
      <Typography color="textSecondary" variant="subtitle2">Use one or any combination to confirm enter and exit positions</Typography>

    {/* Volume Profile */}
   <Accordion >
    <AccordionSummary
      
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >

    <FormControlLabel
      
      control={<Switch checked={user?.isVolumeProfileEnabled} onChange={(e) => dispatch(updateLocalUser({ isVolumeProfileEnabled: e.target.checked }))} name="Enable Volume Profile" />}
      label="Enable Volume Profile" />

    </AccordionSummary>
    <AccordionDetails>
      <Typography>Sells when the bearish volume is higher than the bullish volume</Typography>
      <Typography sx={{ mb: 6 }}>Buys when the bullish volume is higher than the bearish volume</Typography>

      <Slider
        value={user?.vpNumberOfBars || 14}
        onChange={(e: any) => dispatch(updateLocalUser({ vpNumberOfBars: e.target.value }))}
        valueLabelDisplay="on"
      />

    <Typography variant="caption" color="textSecondary">Number of bars (default 14 periods)</Typography>
    
    </AccordionDetails>
  </Accordion>

  {/* On Balance Volume */}
  <Accordion >
    <AccordionSummary
      
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >

    <FormControlLabel
      
      control={<Switch checked={user?.isObvEnabled} onChange={(e) => dispatch(updateLocalUser({ isObvEnabled: e.target.checked }))} name="Enable On Balance Volume" />}
      label="Enable On Balance Volume" />

    </AccordionSummary>
    <AccordionDetails>
      <Typography>Sells when the long EMA volume is higher than the short EMA volume ({user?.obvShortPeriod})</Typography>
      <Typography sx={{ mb: 6 }}>Buys when the short EMA volume is higher than the long EMA volume ({user?.obvLongPeriod})</Typography>

      <Slider
        max={200}
        min={1}
        value={[user?.obvShortPeriod || 9, user?.obvLongPeriod || 21]}
        onChange={(e: any) => {

          const short = e.target.value[0]
          const long = e.target.value[1]

          dispatch(updateLocalUser({ obvShortPeriod: short, obvLongPeriod: long }))
        }}
        valueLabelDisplay="on"
      />

    <Typography variant="caption" color="textSecondary">EMA trend of the volume (defaults are 9 and 21)</Typography>
    
    </AccordionDetails>
  </Accordion>

      {/* Volume Crossover */}
      <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >

    <FormControlLabel
      control={<Switch checked={user?.isVolumeCrossoverEnabled} onChange={(e) => dispatch(updateLocalUser({ isVolumeCrossoverEnabled: e.target.checked }))} name="Enable Volume Crossover" />}
      label="Enable EMA Volume Crossover" />

    </AccordionSummary>
    <AccordionDetails>
      <Typography>Sells when the short volume ({user?.shortVolumeCrossover}) is lower than the long volume ({user?.longVolumeCrossover})</Typography>
      <Typography sx={{ mb: 6 }}>Buys when the short volume ({user?.shortVolumeCrossover}) is higher than the long volume ({user?.longVolumeCrossover})</Typography>

      <Slider
        value={[ user?.shortVolumeCrossover || 9, user?.longVolumeCrossover || 21 ]}
        onChange={(e: any) => dispatch(updateLocalUser({ shortVolumeCrossover: e.target.value[0], longVolumeCrossover: e.target.value[1] }))}
        valueLabelDisplay="on"
      />

    <Typography variant="caption" color="textSecondary">Defaults are 9/21</Typography>
    
    </AccordionDetails>
  </Accordion>

     {/* Dynamic */}
     <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.isDynamicEnabled} onChange={(e) => dispatch(updateLocalUser({ isDynamicEnabled: e.target.checked }))} name="Enable Dynamic" />}
      label={`Enable Dynamic (Current status: ${user?.currentIndicator || "Undetermined"})`} />
    </AccordionSummary>
    <AccordionDetails>

      <Typography variant="subtitle2" color="textSecondary">Buys and sells based on price change percent</Typography>
      <Typography variant="subtitle2" color="textSecondary">{`When price change percent is greater than ${user?.switchToTrendingIndicatorThreshold}% Triple EMA will be used to buy and sell.`}</Typography>
      <Typography variant="subtitle2" color="textSecondary">{`When price change percent is less than ${user?.switchToTrendingIndicatorThreshold}% RSI or MFI will be used to buy and sell.`}</Typography>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>{`The highest win rate backtest between RSI and MFI will be used to determine which indicator to use.`}</Typography>

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Price change percent threshold</Typography>
      <Slider
        disabled={!user?.isDynamicEnabled}
        style={{ color: getSliderColor(user?.switchToTrendingIndicatorThreshold) }}
        min={-10}
        max={10}
        step={0.1}
        value={user?.switchToTrendingIndicatorThreshold}
        onChange={(e: any) => dispatch(updateLocalUser({ switchToTrendingIndicatorThreshold: e.target.value }))}
        valueLabelDisplay="on"
      />

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Custom RSI Configuration</Typography>
      <Slider
        value={[user?.rsiThresholdBuy || 30, user?.rsiThresholdSell || 70]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]

          dispatch(updateLocalUser({ rsiThresholdBuy: buyThreshold, rsiThresholdSell: sellThreshold}))

        }}
        valueLabelDisplay="on"
      />

    <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Custom MFI Configuration</Typography>
      <Slider
        value={[user?.mfiThresholdBuy || 30, user?.mfiThresholdSell || 70]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]

          dispatch(updateLocalUser({ mfiThresholdBuy: buyThreshold, mfiThresholdSell: sellThreshold}))

        }}
        valueLabelDisplay="on"
      />

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Triple EMA configuration</Typography>
      <Slider
        max={200}
        value={[user?.tripleEmaShortPeriod || 12, user?.tripleEmaMediumPeriod || 20, user?.tripleEmaLongPeriod || 50]}
        onChange={(e: any) => {
          const newEmaShortPeriod = e.target.value[0]
          const newEmaMediumPeriod = e.target.value[1]
          const newEmaLongPeriod = e.target.value[2]

          dispatch(updateLocalUser({ tripleEmaShortPeriod: newEmaShortPeriod, tripleEmaMediumPeriod: newEmaMediumPeriod, tripleEmaLongPeriod: newEmaLongPeriod }))

        }}
        disableSwap
        valueLabelDisplay="on"
      />

    </AccordionDetails>
  </Accordion>

  {/* RSI Crossover */}

  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.rsiCrossOverEnabled} 
      onChange={(e) => dispatch(updateLocalUser({ rsiCrossOverEnabled: e.target.checked }))} name="Enable RSI Crossover" />}
      label="Enable RSI Crossover" />
    </AccordionSummary>
    <AccordionDetails>

      <Typography variant="subtitle2" color="textSecondary">Buys and sells based on RSI crossover</Typography>

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Crossover values</Typography>
      <Slider
        value={[user?.rsiShort || 7, user?.rsiMedium || 14, user?.rsiLong || 20 ]}
        onChange={(e: any) => {
          const rsiShortValue = e.target.value[0]
          const rsiMediumValue = e.target.value[1]
          const rsiLongValue = e.target.value[2]


          dispatch(updateLocalUser({ rsiShort: rsiShortValue, rsiMedium: rsiMediumValue, rsiLong: rsiLongValue }))

        }}
        valueLabelDisplay="on"
      />

    <Typography variant="subtitle2" color="textSecondary">{`Sell rule: Once the RSI short period of ${user?.rsiShort} crosses below the RSI medium period of ${user?.rsiMedium} and the
      RSI short is also below the RSI long and the medium is below the long period of ${user?.rsiLong} then it will sell.`}</Typography>

  <Typography variant="subtitle2" color="textSecondary">{`Buy rule: Once the RSI short period of ${user?.rsiShort} crosses above the RSI medium period of ${user?.rsiMedium} and the
      RSI short is also above the RSI long and the medium is above the long period of ${user?.rsiLong} then it will buy.`}</Typography>

    </AccordionDetails>
  </Accordion>

    {/* Triple RSI */}

    <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.isTripleRSIEnabled} 
      onChange={(e) => dispatch(updateLocalUser({ isTripleRSIEnabled: e.target.checked }))} name="Enable Triple RSI" />}
      label="Enable Triple RSI" />
    </AccordionSummary>
    <AccordionDetails>

      <Typography variant="subtitle2" color="textSecondary">Buys and sells based RSI from 3 different time frames. Defaults are 15m, 1h, and 4h. For example for a sell to happen when you have configured RSI overbought setting of 60. The RSI needs to be above 60 for all timeframes.</Typography>
      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Chart Intervals</Typography>
      <TripleRsiCandles />
      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>RSI Settings</Typography>

      <Slider
        value={[user?.rsiThresholdBuy || 30, user?.rsiThresholdSell || 70]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]

          dispatch(updateLocalUser({ rsiThresholdBuy: buyThreshold, rsiThresholdSell: sellThreshold}))

        }}
        valueLabelDisplay="on"
      />

    </AccordionDetails>
  </Accordion>


    <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >

    <FormControlLabel
      control={<Switch checked={user?.macdEnabled} onChange={(e) => dispatch(updateLocalUser({ macdEnabled: e.target.checked }))} name="Enable MACD" />}
      label="Enable MACD" />

    </AccordionSummary>
    <AccordionDetails>

      <MACDSlider 
        onChangeValue={(value) => dispatch(updateLocalUser({ macdSellThreshold: value }))} 
        currentThreshold={user?.macdSellThreshold} 
        title="MACD sell threshold (A lower number means it's more sensitive to market changes - e.g use 2 with 1m)" />

      <MACDSlider 
        onChangeValue={(value) => dispatch(updateLocalUser({ macdBuyThreshold: value }))} 
        currentThreshold={user?.macdBuyThreshold} 
        title="MACD buy threshold (A lower number means it's more sensitive to market changes - e.g use 2 with 1m)" />

    </AccordionDetails>
  </Accordion>

    {/* ADX  */}
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >

    <FormControlLabel
      control={<Switch checked={user?.isAdxEnabled} onChange={(e) => dispatch(updateLocalUser({ isAdxEnabled: e.target.checked }))} name="Enable ADX" />}
      label="Enable ADX" />

    </AccordionSummary>
    <AccordionDetails>

    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 6 }}>
       Enable trend detection - only used to enter trades - By default ADX can detect trends when the ADX is above 25. The trend could be in either direction. So this can be combined
       with EMA so it will only enter a trade when the ADX is trending.
    </Typography>

    <Slider
        max={100}
        value={user?.adxTrendLimit}
        onChange={(e: any) => dispatch(updateLocalUser({ adxTrendLimit: Number(e.target.value) }))}
        disableSwap
        valueLabelDisplay="on"
      />

    </AccordionDetails>
  </Accordion>


  {/* EMA */}
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.emaEnabled} onChange={(e) => dispatch(updateLocalUser({ emaEnabled: e.target.checked }))} name="Enable EMA" />}
      label="Enable EMA crossover" />
    </AccordionSummary>
    <AccordionDetails>

        <Slider
        max={200}
        value={[user?.emaShortPeriod || 8, user?.emaLongPeriod || 20]}
        onChange={(e: any) => {
          const newEmaShortPeriod = e.target.value[0]
          const newEmaLongPeriod = e.target.value[1]
          dispatch(updateLocalUser({ emaShortPeriod: newEmaShortPeriod, emaLongPeriod: newEmaLongPeriod }))
        }}
        disableSwap
        valueLabelDisplay="on"
      />

      <EmaChooser emaType={user?.emaType} onChange={(type) => dispatch(updateLocalUser({ emaType: type }))}/>
      <Typography variant="subtitle2" color="textSecondary">Buys and sells based on EMA line crossovers. 8 and 20 period by default. Double and Triple EMA can be selected to reduce EMA lag.</Typography>


    </AccordionDetails>
  </Accordion>

    {/* Triple EMA */}
    <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.tripleEmaEnabled} onChange={(e) => dispatch(updateLocalUser({ tripleEmaEnabled: e.target.checked }))} name="Enable Triple EMA" />}
      label="Enable Triple EMA crossover" />
    </AccordionSummary>
    <AccordionDetails>

        <Slider
        max={200}
        value={[user?.tripleEmaShortPeriod || 12, user?.tripleEmaMediumPeriod || 20, user?.tripleEmaLongPeriod || 50]}
        onChange={(e: any) => {
          const newEmaShortPeriod = e.target.value[0]
          const newEmaMediumPeriod = e.target.value[1]
          const newEmaLongPeriod = e.target.value[2]

          dispatch(updateLocalUser({ tripleEmaShortPeriod: newEmaShortPeriod, tripleEmaMediumPeriod: newEmaMediumPeriod, tripleEmaLongPeriod: newEmaLongPeriod }))

        }}
        disableSwap
        valueLabelDisplay="on"
      />

      <Typography variant="subtitle2" color="textSecondary">
        Buys and sells based on Triple EMA line crossovers. Buys when: the short ema crosses above medium and the long and the medium crosses above the long. 
        Sells when: the short crosses below the medium and the long, and the medium crosses below the long.</Typography>


    </AccordionDetails>
  </Accordion>

   {/* Custom */}
    <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.customEnabled} onChange={(e) => dispatch(updateLocalUser({ customEnabled: e.target.checked }))} name="Enable Custom" />}
      label="Enable Custom" />
    </AccordionSummary>
    <AccordionDetails>

      <Typography variant="subtitle2" color="textSecondary">Buys and sells based on various indicators. </Typography>
      <Typography variant="subtitle2" color="textSecondary">Sell rule: will sell if the triple EMA signals a downtrend OR the RSI is overbought. </Typography>
      <Typography variant="subtitle2" color="textSecondary" style={localStyle.subHeading}>Buy Rule: Will buy is there is a triple EMA crossover and a bullish candlestick and the RSI is at least 10% lower than overbought value ({user?.rsiThresholdSell}) - value {(user?.rsiThresholdSell || 0) - 10}</Typography>

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Custom RSI Configuration</Typography>
      <Slider
        value={[user?.rsiThresholdBuy || 30, user?.rsiThresholdSell || 70]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]

          dispatch(updateLocalUser({ rsiThresholdBuy: buyThreshold, rsiThresholdSell: sellThreshold}))

        }}
        valueLabelDisplay="on"
      />

      <Typography variant="subtitle2" color="textSecondary" fontWeight="700" style={localStyle.subHeading}>Triple EMA configuration</Typography>
      <Slider
        max={200}
        value={[user?.tripleEmaShortPeriod || 12, user?.tripleEmaMediumPeriod || 20, user?.tripleEmaLongPeriod || 50]}
        onChange={(e: any) => {
          const newEmaShortPeriod = e.target.value[0]
          const newEmaMediumPeriod = e.target.value[1]
          const newEmaLongPeriod = e.target.value[2]

          dispatch(updateLocalUser({ tripleEmaShortPeriod: newEmaShortPeriod, tripleEmaMediumPeriod: newEmaMediumPeriod, tripleEmaLongPeriod: newEmaLongPeriod }))

        }}
        disableSwap
        valueLabelDisplay="on"
      />

    </AccordionDetails>
  </Accordion>

   {/* Single SMA */}
   <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header" >

    <FormControlLabel
      control={<Switch checked={user?.smaEnabled} onChange={(e) => dispatch(updateLocalUser({ smaEnabled: e.target.checked }))} name="Enable SMA" />}
      label="Enable Single SMA" />
    </AccordionSummary>
    <AccordionDetails>

      <Typography variant="subtitle2" color="textSecondary">
        Buys and sells based on a single SMA 10 period - good to combine with other indicators
        </Typography>

    </AccordionDetails>
  </Accordion>

      {/* RSI */}
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header" >

      <FormControlLabel
        control={<Switch checked={user?.rsiEnabled} onChange={(e) => dispatch(updateLocalUser({ rsiEnabled: e.target.checked }))} name="Enable RSI" />}
        label="Enable RSI" />

      </AccordionSummary>
      <AccordionDetails>

      <Slider
        value={[user?.rsiThresholdBuy || 30, user?.rsiThresholdSell || 70]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]

          dispatch(updateLocalUser({ rsiThresholdBuy: buyThreshold, rsiThresholdSell: sellThreshold}))

        }}
        valueLabelDisplay="on"
      />

      <Typography color="textSecondary" variant="subtitle2">Default values: Bot will buy when RSI is below 30 (Oversold) and sell when RSI is above 70 (Overbought)</Typography>
      </AccordionDetails>
    </Accordion>

    {/* MFI */}
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header" >

      <FormControlLabel
        control={<Switch checked={user?.mfiEnabled} onChange={(e) => dispatch(updateLocalUser({ mfiEnabled: e.target.checked }))} name="Enable MFI" />}
        label="Enable MFI" />

      </AccordionSummary>
      <AccordionDetails>

      <Slider
        value={[user?.mfiThresholdBuy || 20, user?.mfiThresholdSell || 80]}
        onChange={(e: any) => {
          const buyThreshold = e.target.value[0]
          const sellThreshold = e.target.value[1]
          dispatch(updateLocalUser({ mfiThresholdBuy: buyThreshold, mfiThresholdSell: sellThreshold }));
        }}
        valueLabelDisplay="on"
      />

      <Typography color="textSecondary" variant="subtitle2">Default values: Bot will buy when MFI is below 20 (Oversold) and sell when MFI is above 70 (Overbought)</Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >

      <FormControlLabel
        control={<Switch checked={user?.candleStickPatternEnabled} onChange={(e) => dispatch(updateLocalUser({ candleStickPatternEnabled: e.target.checked }))} name="Enable CandleStick Pattern" />}
        label="Enable CandleStick Pattern Bullish Bearish Check" />

      </AccordionSummary>
      <AccordionDetails>

      <Typography variant="subtitle2">Enabling will use all of the following candle stick patterns</Typography>

        <Typography color="textSecondary" variant="caption">
          Abandoned Baby.
          Bearish Engulfing Pattern.
          Bullish Engulfiing Pattern.
          Dark Cloud Cover.
          Downside Tasuki Gap.
          Doji.
          DragonFly Doji.
          GraveStone Doji.
          BullishHarami.
          Bearish Harami Cross.
          Bullish Harami Cross.
          Bullish Marubozu.
          Bearish Marubozu.
          Evening Doji Star.
          Evening Star.
          Bearish Harami.
          Piercing Line.
          Bullish Spinning Top.
          Bearish Spinning Top.
          Morning Doji Star.
          Morning Star.
          Three Black Crows.
          Three White Soldiers.
          Bullish Hammer.
          Bearish Hammer.
          Bullish Inverted Hammer.
          Bearish Inverted Hammer.
          Hammer Pattern.
          Hammer Pattern (Unconfirmed).
          Hanging Man.
          Hanging Man (Unconfirmed).
          Shooting Star.
          Shooting Star (Unconfirmed).
          Tweezer Top.
          Tweezer Bottom.
        </Typography>

      </AccordionDetails>
    </Accordion>
  </div>
  )
}

const localStyle = {
  subHeading: {
    marginBottom: 34,
  }
}