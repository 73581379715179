import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

interface TabSectionProps
{
  tab: number
  setTab: (index: number) => void
}

export const TabSection = ({ tab, setTab }: TabSectionProps) => {


  return (
    <Tabs value={tab} onChange={(_, value) => setTab(value)}>
      <Tab label="Orders" />
      <Tab label="Profit Summary" />
      <Tab label="Bot History" />
    </Tabs>
    
  )
}