import Dialog from "@mui/material/Dialog"
import { Transition } from "../../../animations/Transition"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import CircularProgress from "@mui/material/CircularProgress"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import Box from "@mui/material/Box"

import CloseIcon from "@mui/icons-material/Close"

import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import { GridApi } from "../../../api/GridApi"
import { IGridBotConfig } from "../../../interface/GridBotConfig"
import Typography from "@mui/material/Typography"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import TextField from "@mui/material/TextField"
import Slider from "@mui/material/Slider"
import LinearProgress from "@mui/material/LinearProgress"

import { AlertColor } from "@mui/material"
import { getBotConfigs, getCandles, getMarketData, setCurrentBotConfig } from "../../../redux/reducers/grid/gridReducer"
import { calculateGridLevels } from "../util/calculateGridLevels"
import { Rocket } from "@mui/icons-material"
import { PreviewGridChart } from "../chart/PreviewGridChart"
import { useMarketDetails } from "./hooks/useMarket"
import { LogApi } from "../../../api/LogApi"
import { splitTradingPair } from "../../../utils/splitTradingPair"
import { calculateGridRange } from "../util/suggestGridRange"
import { GridLevelsPreview } from "../table/GridLevelsPreview"
import { CreateBuyResult } from "../../../interface/CreateBuyResult"
import { CreateBuyResultsTable } from "../table/CreateBuyResultsTable"

interface AddBotConfigProps
{
  open: boolean
  onClose: () => void
}

export const AddBotConfig = ({ onClose, open }: AddBotConfigProps) => {

  const dispatch = useDispatch()
  const tradingPair = useSelector((state: ApplicationState) => state.grid.tradingPair)
  const marketData = useSelector((state: ApplicationState) => state.grid.marketData)
  const isGettingMarkets = useSelector((state: ApplicationState) => state.grid.isGettingMarkets)
  const candles = useSelector((state: ApplicationState) => state.grid.candles)
  const interval = useSelector((state: ApplicationState) => state.grid.interval)

  // Model
  const [ name, setName ] = useState("")
  const [ priceRangeLow, setPriceLow ] = useState(0)
  const [ priceRangeHigh, setPriceHigh  ] = useState(0)
  const [ numberOfGrids, setNumberOfGrids  ] = useState(10)
  const [ maxInvest, setMaxInvest ] = useState(100)

  // Response
  const [ loading, setLoading ] = useState(false)
  const [ suggest, setSuggest ] = useState(false)

  // UI
  const [ snack, setSnack ] = useState({ message: "", color: "" })
  const [ currentFunds, setCurrentFunds ] = useState(0)
  const [ tabIndex, setTabIndex] = useState(0)
  const [ createSuccess, setCreateSuccess ] = useState<CreateBuyResult[] | undefined>(undefined)

  const symbolData = useMarketDetails(marketData, tradingPair)

  const { investmentPerGrid, gridLevels, totalProfit, priceSpacing, totalProfitPercentage } = calculateGridLevels(numberOfGrids, maxInvest, Number(priceRangeHigh), Number(priceRangeLow), Number(symbolData?.lastPrice))

  useEffect(() => {

    dispatch(getMarketData())
    getCurrentFundsForBaseAsset()

  }, [])

  // When the market data changes - set the price range
  useEffect(() => {

    suggestOn1Percent()

  }, [marketData])

  useEffect(() => {

    if(suggest) suggestRange()
    if(!suggest) suggestOn1Percent()

  }, [ candles, suggest ])

  useEffect(() => {

    if(tradingPair && interval) dispatch(getCandles({ interval, tradingPair }))

  }, [ tradingPair, interval ])

  const suggestOn1Percent = () => {

    if(!symbolData) return
    setPriceHigh(Number(symbolData.lastPrice) + (Number(symbolData.lastPrice) * 0.01)) // Suggest 1 percent lower than last price
    setPriceLow(Number(symbolData.lastPrice) - (Number(symbolData.lastPrice) * 0.01)) // Suggest 1 percent higher than last price
  }

  const getPercentageOfPrice = (rangePrice: number, marketPrice: number) => {
    return (((rangePrice - marketPrice ) / marketPrice) * 100).toFixed(2)
  }

  const getCurrentFundsForBaseAsset = async () =>
    {
      try
      {
        const summary = await new LogApi().getSummary("")
        const { base, coin } = splitTradingPair(tradingPair)
        const baseAmount = summary?.account.balances.find(coin => coin.asset === base)
        setCurrentFunds(Number(Number(baseAmount?.free).toFixed(0)) || 0)

      }
      catch(error)
      {
        console.error("Error setting max funds")
      }
  
    }

  const create = async () => {

    setLoading(true)
    try
    {
      const config: IGridBotConfig = {
        name,
        priceRangeLow: Number(priceRangeLow),
        priceRangeHigh: Number(priceRangeHigh),
        numberOfGrids,
        maxInvest,
        tradingPair,
        isEnabled: true,
        gridLevels,
        buyOrderHistory: [],
        sellOrderHistory: []
      }
      const response = await new GridApi().create(config)

      const failedBuys = response?.buyResults.filter(r => !r.success) || []

      if(response && response.gridConfig && failedBuys.length === 0)
      {
        setSnack({ message: "Successfully created new grid bot", color: "success" })
        dispatch(setCurrentBotConfig(response.gridConfig))
        dispatch(getBotConfigs())
        setCreateSuccess(response.buyResults)
      }

    if(response && response.gridConfig && failedBuys.length > 0)
      {
        setSnack({ message: `We created your grid bot but we failed to place some or all of the buy orders. Check logs for more details. You will need to stop and remove your bot.`, color: "error" })
        dispatch(setCurrentBotConfig(response.gridConfig))
        dispatch(getBotConfigs())
        setCreateSuccess(response.buyResults)
      }

    }
    catch(error)
    {
      const errorCast = error as any
      setSnack({ message: errorCast?.message?.response?.data?.error?.message || "Looks like something went wrong while creating the grid bot", color: "error"})
    }

    setLoading(false)

  }

  const suggestRange = () => {
    const { maxPrice, minPrice } = calculateGridRange(candles)
    setPriceHigh(maxPrice)
    setPriceLow(minPrice)
  }

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullWidth maxWidth="xl">

      <DialogTitle>Create Grid Bot [{tradingPair}]</DialogTitle>

     <DialogContent>

     { !createSuccess&& <Grid container direction="row" spacing={3}>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)} sx={{ mb: 0.5 }} centered >
          <Tab label="Grid Chart" value={0}/>
          <Tab label="Grid Levels" value={1} />
        </Tabs>
          { tabIndex === 0 && <PreviewGridChart height={607} candles={candles} chartInterval={interval} tradingPair={tradingPair} gridLevels={gridLevels} setInterval={(interval) => dispatch(getCandles({ interval, tradingPair }))} /> }
          { tabIndex === 1 && <GridLevelsPreview gridLevels={gridLevels} />}

        </Grid>

      <Grid item container direction="column" spacing={3} xs={12} sm={12} md={6} lg={6} xl={6}>

      <Grid item>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Bot Name</Typography>
        <TextField placeholder="Bot Name - E.G. BitCoin Grid Bot" fullWidth value={name} onChange={(e: any) => setName(e.target.value)}/>
      </Grid>

      <Grid item>
        <Typography fontWeight={800}>Market Price of Asset: ${symbolData?.lastPrice}</Typography>
      </Grid>

      <Grid item container spacing={3}>

      <Grid item xs={6}>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Price Range Low</Typography>
        <TextField 
            placeholder="Price Range Low" 
            fullWidth 
            value={priceRangeLow} 
            onChange={(e: any) => setPriceLow(e.target.value)} 
            helperText={`${getPercentageOfPrice(priceRangeLow, Number(symbolData?.lastPrice))}% lower than last price`}/>
        
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Price Range High</Typography>
        <TextField 
          helperText={`${getPercentageOfPrice(priceRangeHigh, Number(symbolData?.lastPrice))}% higher than last price`}
          placeholder="Price Range High" 
          fullWidth value={priceRangeHigh}
          onChange={(e: any) => setPriceHigh(e.target.value)}/>
      </Grid>

      </Grid>

      <Grid item>
        <Typography fontWeight={800}>Price range is: ${Number(priceRangeHigh) - Number(priceRangeLow)}</Typography>
        <FormControlLabel label="Suggest price range based on candle data" control={<Checkbox />} checked={suggest} onChange={(_, checked) => setSuggest(true)} />
        <br></br>
        <FormControlLabel label="Set price range based on 1% of market" control={<Checkbox />} checked={!suggest} onChange={(_, checked) => setSuggest(false)} />
      </Grid>

      <Grid item>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Number of grids</Typography>
        <Slider sx={{ mt: 5 }} min={1} max={100} value={numberOfGrids} onChange={(_, value) => setNumberOfGrids(value as number)} marks valueLabelDisplay="on"/>
      </Grid>

      <Grid item>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Max Investment (USDT) - Currents Funds: ${currentFunds}</Typography>

        <Slider sx={{ mt: 5 }} min={1} max={currentFunds} value={maxInvest} onChange={(_, value) => setMaxInvest(value as number)} valueLabelDisplay="on"/>
      </Grid>

      <Grid item >
        <div style={styles.textSection}>
          <Typography fontWeight={800}>Price spacing ${priceSpacing.toFixed(2)}</Typography>
          <Typography fontWeight={800}>Buy/Sell amount per grid: ${investmentPerGrid.toFixed(2)}</Typography>
          <Typography fontWeight={800}>Profit if all sells are matched on first iteration ${totalProfit.toFixed(2)} ({totalProfitPercentage?.toFixed(2)}%)</Typography>

        </div>
      </Grid>

</Grid>


      </Grid> }

      { createSuccess && <CreateBuyResultsTable buyResults={createSuccess}/> }
 
     </DialogContent>

     <DialogActions>
      <Button color="secondary" onClick={onClose} startIcon={<CloseIcon />}>Close</Button>
      <Box flex={1} />
      { !snack.message && <Button 
        startIcon={loading ? <CircularProgress size={16} color="secondary" /> : <Rocket />} 
        onClick={create} 
        disabled={loading || !name || !priceRangeLow || !priceRangeHigh || !numberOfGrids || (Number(priceRangeHigh) < Number(priceRangeLow))} 
         >{ loading ? "Creating Bot" : "Create Bot"} </Button> }
     </DialogActions>

     <Snackbar open={!!(snack.message && snack.color)}>
      <Alert variant="filled" color={snack.color as AlertColor}>{snack.message}</Alert>
     </Snackbar>

     { (isGettingMarkets || loading) && <LinearProgress />}

    </Dialog>
    
  )
}

const styles = {
  textSection: {
    border: "1px solid grey", borderRadius: 12, padding: 12
  }
}