import { useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid"
import { green } from "@mui/material/colors"


export const ProfitSummary = () => {

  const configs = useSelector((state: ApplicationState) => state.grid.botConfigs)

  const getTotalProfit = () => {
    let total = 0
    let floating = 0
    let activeProfit = 0


    for(const config of configs)
    {
      for(const history of config.sellOrderHistory)
      {
        if(history.isFilled) total = total + history.profit
        if(history.isFilled && config.isEnabled) activeProfit = activeProfit + history.profit
        if(!history.isFilled && config.isEnabled) floating = floating + history.profit
      }
    }

    return { total, floating, activeProfit }
  }

  const { total, floating, activeProfit } = getTotalProfit()

  return (<Grid container direction="column" flex={1} spacing={2} sx={{ mt: 1, mb: 1  }}>

    <Grid item>
      <Card style={styles.profitCard}>
        <CardHeader avatar={<Avatar style={styles.avatar}>${total.toFixed(2)}</Avatar>} title="Total Profits" subheader="Total profit from all bots active and stopped">
        </CardHeader>
      </Card>
    </Grid>
  

    <Grid item>
      <Card style={styles.profitCard}>
        <CardHeader avatar={<Avatar style={styles.avatar}>${activeProfit.toFixed(2)}</Avatar>} title="Active Bot Profits" subheader="Total profit from all bots active only">
        </CardHeader>
      </Card>
    </Grid>
  
    <Grid item>
      <Card style={styles.profitCard}>
        <CardHeader avatar={<Avatar style={styles.avatar}>${floating.toFixed(2)}</Avatar>} title="Floating Profit" subheader="Floating profit from all active bots that have placed buy orders but sell grid not reached">
        </CardHeader>
      </Card>
    </Grid>
  
  </Grid>)
}

const styles = {
  profitCard: {
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: green[400]
  }
}