import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import LinearProgress from "@mui/material/LinearProgress"

import Typography from "@mui/material/Typography"
import Rocket from "@mui/icons-material/Rocket"

import { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import List from "@mui/material/List"
import { BotConfigItem } from "./list/BotConfigItem"
import { AddBotConfig } from "./modal/AddBotConfig"
import { EditBotConfig } from "./modal/EditBotConfig"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { closeEditBot, getBotConfigs } from "../../redux/reducers/grid/gridReducer"
import moment from "moment"


export const BotList = () => {

  const dispatch = useDispatch()
  const showEditBotModal = useSelector((state: ApplicationState) => state.grid.showEditBotModal)
  const isGettingBotConfigs = useSelector((state: ApplicationState) => state.grid.isGettingBotConfigs)

  const botConfigs = useSelector((state: ApplicationState) => state.grid.botConfigs)
  const lastUpdate = useSelector((state: ApplicationState) => state.grid.lastUpdate)

  const [ showAddBot, setShowAddBot ] = useState(false)

  useEffect(() => {
    dispatch(getBotConfigs())
  }, [])


  return (
    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
      <Grid item container alignItems="center">
      <Typography fontWeight={700} sx={{ ml: 0.5 }}>Bot Config</Typography>
      <Typography sx={{ ml: 0.5 }} color="textSecondary" fontSize={12}>{lastUpdate ? ` - Last update: ${moment(lastUpdate).fromNow()}` : ""}</Typography>
      </Grid>
     
      <Card style={styles.cardContainer}> 

        <List style={styles.list}>

          { botConfigs?.filter(b => b.isEnabled).map((bot, key) => <BotConfigItem item={bot} key={key}  />)}

        </List>

        { isGettingBotConfigs && <LinearProgress  />}

      </Card>

      <Button startIcon={<Rocket />} variant="contained" color="info" fullWidth onClick={() => setShowAddBot(true)}>Create New Bot</Button>

      { showAddBot && <AddBotConfig open={showAddBot} onClose={() => setShowAddBot(false)} /> }
      { showEditBotModal && <EditBotConfig open={showEditBotModal} onClose={() => dispatch(closeEditBot())}  /> }


    </Grid>
  )
}

const styles = {
  cardContainer: {
    borderRadius: 12,
    marginBottom: 12,
  },
  list: {
    overflow: "auto",
    height: "47.8vh"
  }
}