import { useState } from "react"
import { useEffect } from "react"
import { Stripe } from "stripe";
import { BillingApi } from "../../api/BillingApi"
import List from '@mui/material/List';


import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { SubscriptionItem } from "./SubscriptionItem";

interface SelectPlanProps
{
  setSelectedProductId: (priceId: string) => void;
  selectedProductId: string

}

export const SelectPlan = ({ selectedProductId, setSelectedProductId }: SelectPlanProps) =>
{
  const [ products, setProducts ] = useState<Stripe.Response<Stripe.ApiList<Stripe.Product>> | undefined>();
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {

    getProducts()

  }, [ ])

  const getProducts = async () =>
  {
    try
    {
      setLoading(true)
      const billingApi = new BillingApi()
      const products = await billingApi.getProducts()
      setProducts(products)
      setLoading(false)

    }
    catch(error)
    {
      console.log(error)
      setLoading(false)
    }

  }

  return(<div>
   
   <Typography style={styles.subscriptionText} color="textSecondart" variant="subtitle1">Choose your subscription below</Typography>
   <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, mb: 4 }}>
     { !loading && products ? products?.data.filter(p => p.active).map((product, key) => <SubscriptionItem key={key} product={product} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} /> ): loading ? <Skeleton animation="wave" style={styles.loading} /> : <Typography color="textSecondary" style={styles.text}>No Products Found</Typography>} 
    </List>


  </div>)
}

const styles = {
  loading: {
    marginRight: 6,
    marginLeft: 6,
    height: 100,
  },
  subscriptionText: {
    marginBottom: 12,
    fontWeight: "700"
  },
  text: {
    textAlign: 'center' as 'center',
    margin: 16,
  }
}