import Card from '@mui/material/Card'
import { AVATAR_SIZE, styles } from '../../Pages/Dashboard'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'

import Avatar from '@mui/material/Avatar'

import { Summary, SummaryItem } from '../../interface/Summary'

import { getBalanceForAsset } from '../../utils/getBalanceForAsset'
import Tooltip from '@mui/material/Tooltip'
import { formatPrice } from '../../utils/formatPrice'

interface DailyStatProps
{
  summary: Summary | undefined
  summaryItem: SummaryItem | undefined
  title: string
  loading: boolean
  color: string
  assetName: string
}

export const DailyStat = ({ summaryItem, summary, title, loading, assetName, color }: DailyStatProps) =>
{

  const getChangePercentColor = () =>
  {
    if(summary && summaryItem && Number(summaryItem?.dailyStats.priceChangePercent) > 0) return "#66BB6A"
    return "#FF5722"
  }

  return(
    <Card style={styles.card}>
    { !loading ? <Typography fontWeight={700} color="textSecondary">{title}</Typography> : <Skeleton width={200} />}

    <Divider sx={{ mb: 1 }} />

    <div style={{ display: 'flex', flexDirection: "row"}}>


    <div style={{ display: 'flex', flexDirection: "column", flex: 1}}>
      { !loading ? 
      <>
      <Typography color="textSecondary" variant="caption">Asset Value (USD): ${summary && formatPrice(summaryItem?.estValueInUsd)}</Typography>
      <Typography color="textSecondary" variant="caption">Ask Price: ${summary && formatPrice(Number(summaryItem?.dailyStats.askPrice))}</Typography>
      <Typography color="textSecondary" variant="caption">Bid Price: ${summary && formatPrice(Number(summaryItem?.dailyStats.bidPrice))}</Typography>
      <Typography color="textSecondary" variant="caption">High Price: ${summary && formatPrice(Number(summaryItem?.dailyStats.highPrice))}</Typography>
      <Typography color="textSecondary" variant="caption">Last Price: ${summary && formatPrice(Number(summaryItem?.dailyStats.lastPrice))}</Typography>
      <Typography color="textSecondary" variant="caption">Low Price: ${summary && formatPrice(Number(summaryItem?.dailyStats.lowPrice))}</Typography>
      <Typography color="textSecondary" variant="caption">Price Change: ${summary && formatPrice(Number(summaryItem?.dailyStats.priceChange))}</Typography>
      <Typography color="textSecondary" variant="caption" style={{ backgroundColor: getChangePercentColor(), borderRadius: 10, width: 160, padding: 2}}>Change Percent: {summary && Number(summaryItem?.dailyStats.priceChangePercent)}%</Typography> 
       </>
      :
      [0, 0, 0, 0, 0, 0, 0, 0].map((i, key) => <Skeleton width={150} key={key} />)
      }

    </div>

    {loading ? 
    <Skeleton style={styles.loading} variant="circular" width={AVATAR_SIZE} height={AVATAR_SIZE} /> : 
     
      <Tooltip title={`Current ${assetName}`}>
        <Avatar style={{ fontWeight: "700", width: AVATAR_SIZE, height: AVATAR_SIZE, float: "right", backgroundColor: color }}>{getBalanceForAsset(summary, assetName)?.free}</Avatar>
      </Tooltip>
     
    }

    </div>




    </Card>
  )
}