
export const calculateGridLevels = (
  numberOfGrids: number,
  maxInvest: number,
  priceRangeHigh: number,
  priceRangeLow: number,
  marketPrice: number
) => {
  // Validate inputs
  if (numberOfGrids <= 0 || maxInvest <= 0 || priceRangeHigh <= priceRangeLow) {
    return { gridLevels: [], investmentPerGrid: 0, totalProfit: 0, priceSpacing: 0 };
  }

  // Calculate price spacing between grids
  const priceRange = priceRangeHigh - priceRangeLow;
  const priceSpacing = priceRange / numberOfGrids;

  // Generate grid levels
  const gridLevels = [];
  let totalProfit = 0;
  const buyLevels: any[] = [];


  for (let i = 0; i < numberOfGrids; i++) {
    const buyPrice = priceRangeLow + i * priceSpacing; // Lower price for buy
    const sellPrice = priceRangeHigh - i * priceSpacing; // Higher price for sell

    // Ensure the buy price is valid
    if (buyPrice < marketPrice && buyPrice < sellPrice) {
      const { profitPercentage, profitPerGrid } = getProfitPerGrid(buyPrice, sellPrice, maxInvest / numberOfGrids);

      if(profitPerGrid < 0) continue
      totalProfit  = totalProfit + profitPerGrid;
      gridLevels.push({ gridIndex: i, buyPrice, sellPrice, profit: profitPerGrid, investment: 0, profitPercentage });
      buyLevels.push(buyPrice);
    }
  }

  const investmentPerGrid = getInvestmentPerGrid(buyLevels, maxInvest)


  // Add investment to each grid level
  gridLevels.forEach((level) => {
    level.investment = investmentPerGrid;
  });

  const totalProfitPercentage = (totalProfit / maxInvest) * 100;

  return {
    investmentPerGrid: investmentPerGrid,
    gridLevels,
    priceSpacing,
    totalProfit,
    totalProfitPercentage
  };
};

const getInvestmentPerGrid = (buyLevels: any[], maxInvest: number) => {
    // Redistribute investment across valid grid levels
    const actualGridCount = buyLevels.length;
    const investmentPerGrid = actualGridCount > 0 ? maxInvest / actualGridCount : 0;
    return investmentPerGrid
}

const getProfitPerGrid = (buy: number, sell: number, investment: number) => {
  const feePercentage = 0.2; // 0.2% fee per transaction
  const feeMultiplier = 1 - feePercentage / 100; // Convert fee percentage to multiplier

  // Effective sell and buy prices after fees
  const effectiveSell = sell * feeMultiplier;
  const effectiveBuy = buy / feeMultiplier;

  // Net profit per unit
  const profitPerUnit = effectiveSell - effectiveBuy;

  // Total profit in dollars
  const profitPerGrid = (investment / buy) * profitPerUnit; // Units bought * profit per unit

  // Profit percentage
  const profitPercentage = ((effectiveSell - effectiveBuy) / effectiveBuy) * 100;

  return {
    profitPerGrid, // Profit in dollars
    profitPercentage, // Profit in percentage
  };
};
