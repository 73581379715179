import { IGridBotConfig } from "../../../../interface/GridBotConfig"

const getPricePercentageDiff = (price?: number, market?: number) =>
{
  if(!price) return "0" 
  if(!market) return "0" 

  const diff = (price / market) * 100

  return (diff - 100).toFixed(2)
}

export const getStepPrice = (botConfig: IGridBotConfig, lastPrice: string) => {
  const steps = []

  const marketPrice = Number(lastPrice)
  const low = botConfig?.priceRangeLow as number
  const high = botConfig?.priceRangeHigh as number

  if(marketPrice > low && marketPrice < high)
  {
    steps.push({ label: `Price range low: $${low}`, message: `${getPricePercentageDiff(low, marketPrice)}% lower than market`, color: "success" })
    steps.push({ label: `Market Price of asset $${marketPrice}`, message: "Price within grid", color: "success" })
    steps.push({ label: `Price range high: $${high}`, message: `${getPricePercentageDiff(high, marketPrice)}% higher than market`, color: "success" })
  }

  if(marketPrice < low)
  {
    steps.push({ label: `Market Price of asset $${marketPrice}`, message: "Price out of grid", color: "error" })
    steps.push({ label: `Price range low: $${low}`, message: `${getPricePercentageDiff(low, marketPrice)}% lower than market`, color: "success" })
    steps.push({ label: `Price range high: $${high}`, message: `${getPricePercentageDiff(high, marketPrice)}% higher than market`, color: "success" })
  }

  if(marketPrice > high)
  {
    steps.push({ label: `Price range low: $${low}`, message: `${getPricePercentageDiff(low, marketPrice)}% lower than market`, color: "success" })
    steps.push({ label: `Price range high: $${high}`, message: `${getPricePercentageDiff(high, marketPrice)}% higher than market`, color: "success" })
    steps.push({ label: `Market Price of asset $${marketPrice}`, message: "Price out of grid", color: "error"  })
  }


  return steps
}