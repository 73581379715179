import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { MarketListItem } from "./list/MarketListItem"
import List from "@mui/material/List"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import ToggleButton from "@mui/material/ToggleButton"
import { SearchBar } from "../../components/SearchBar"
import Divider from "@mui/material/Divider"
import { LottieLoading } from "../../components/placeholder/LottieLoading"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { getMarketData } from "../../redux/reducers/grid/gridReducer"


export const MarketList = () => {

  const dispatch = useDispatch()
  const marketData = useSelector((state: ApplicationState) => state.grid.marketData)
  const isGettingMarkets = useSelector((state: ApplicationState) => state.grid.isGettingMarkets)

  const [ query, setQuery ] = useState("")
  const [ base, setBase ] = useState("USDT")

  useEffect(() => {
    dispatch(getMarketData())
  }, [ ])


  return (
    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>  

      <Typography fontWeight={700} sx={{ ml: 0.5 }}>Markets</Typography>

      <SearchBar placeholder="Search pairs" setQuery={setQuery} size="small" />

      <div style={styles.buttonGroup}>
          <ToggleButtonGroup exclusive onChange={(_, value) => setBase(value)} value={base} size="small" fullWidth>
            <ToggleButton value="USDT">USDT</ToggleButton>
            <ToggleButton value="TUSD">TUSD</ToggleButton>
            <ToggleButton value="USDC">USDC</ToggleButton>
            <ToggleButton value="FDUSD">FDUSD</ToggleButton>
          </ToggleButtonGroup>
          </div>

      <Card style={styles.cardContainer}>
         
        <Divider />

        { isGettingMarkets && <LottieLoading animation={require("../../animations/graph-lottie.json")} height={100} width={100} loop  text="Getting Pairs" containerHeight="25vh" />}

        { !isGettingMarkets && <List style={styles.list}>
          { marketData?.filter(m => m?.symbol?.toLowerCase().includes(query?.toLowerCase())).filter(m => m.symbol.endsWith(base)).map((item, key) => <MarketListItem key={key} item={item} />)}
          </List> }

      </Card>
    </Grid>
  )
}

const styles = {
  cardContainer: {
    borderRadius: 12,
    height: "43.2vh",
  },
  buttonGroup: {
    marginTop: 6,
    textAlign: 'center' as 'center',
    marginBottom: 6,
  },
  list: {
    overflow: "auto" as "auto",
    height: "50vh"
  }
}