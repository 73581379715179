import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { LottieLoading } from "../../components/placeholder/LottieLoading"
import { GridChart } from "./chart/GridChart"
import { getCandles } from "../../redux/reducers/grid/gridReducer"

export const GridChartSection = () => {

  const dispatch = useDispatch()
  const tradingPair = useSelector((state: ApplicationState) => state.grid.tradingPair)

  const candles = useSelector((state: ApplicationState) => state.grid.candles)
  const isGettingCandles = useSelector((state: ApplicationState) => state.grid.isGettingCandles)
  const interval = useSelector((state: ApplicationState) => state.grid.interval)

  useEffect(() => {

    dispatch(getCandles({ interval, tradingPair }))

  }, [ interval, tradingPair ])


  return (
    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
      <Typography fontWeight={700} sx={{ ml: 0.5 }}>Chart</Typography>
      <Card style={styles.cardContainer}>

      { isGettingCandles && <LottieLoading animation={require("../../animations/graph-lottie.json")} loop height={150} width={150} text="Getting Chart" containerHeight="50vh" />}
      { !isGettingCandles && <GridChart candles={candles} chartInterval={interval} tradingPair={tradingPair} height={400} setInterval={(interval) => dispatch(getCandles({ interval, tradingPair })) }/> }

      </Card>
    </Grid>
  )
}

const styles = {
  cardContainer: {
    borderRadius: 12,
    height: "53vh",
  }
}