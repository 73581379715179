import { put, takeLatest } from "redux-saga/effects";
import { getBotConfigs, getBotConfigsError, getBotConfigsSuccess, getCandles, getCandlesError, getCandlesSuccess, getMarketData, getMarketDataError, getMarketDataSuccess } from "../reducers/grid/gridReducer";
import { GridApi } from "../../api/GridApi";
import { IGridBotConfig } from "../../interface/GridBotConfig";
import { LogApi } from "../../api/LogApi";

function* getMarkets(): any
{
  try
  {
    const marketList = yield new GridApi().getMarketList()
    yield put(getMarketDataSuccess(marketList))
  }
  catch(error)
  {
    yield put(getMarketDataError())
  }

}

function* getConfigs(): any
{

  try
  {
    const botConfigs = yield new GridApi().getConfigs()
    yield put(getBotConfigsSuccess(botConfigs?.sort((a: IGridBotConfig, b: IGridBotConfig) => new Date(b.createdAt!!).valueOf() - new Date(a.createdAt!!).valueOf())))
  }
  catch(error)
  {
    yield put(getBotConfigsError())
  }

}


function* loadCandles(action: { payload:  { tradingPair: string, interval: string } } ): any
{

  try
  {
    const candles = yield new LogApi().getCandles(action.payload.tradingPair, action.payload.interval)
    yield put(getCandlesSuccess(candles))
  }
  catch(error)
  {
    yield put(getCandlesError())
  }

}

export function* gridSaga()
{
  yield takeLatest(getMarketData, getMarkets)
  yield takeLatest(getBotConfigs, getConfigs)
  yield takeLatest(getCandles, loadCandles)
}