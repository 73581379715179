import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser } from '../../redux/actions/userActions';
import { useTheme } from '@mui/material';
import { styles } from '../Settings';


export const LossPrevention = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>

        <Grid item>
          <FormControlLabel
              control={<Switch checked={user.buyOnlyIfChangePositive} onChange={(e) => dispatch(updateLocalUser({ buyOnlyIfChangePositive: e.target.checked }))} name="Change percent positive" />}
              label="Buy only if daily price change percent is positive (default enabled)" />
          </Grid>

        <div style={styles.spacer} />

        <Grid item>
          <FormControlLabel
              control={<Switch checked={user.enableLong} onChange={(e) => dispatch(updateLocalUser({ enableLong: e.target.checked, takeProfitThreshold: 0 }))} name="Go Long" />}
              label="Enable Long Strategy (Don't sell if last buy was more than current sell - same as setting your min sell profit to 0%)" />
          </Grid>

       <div style={styles.spacer} />

       <Grid item>
          <FormControlLabel
              control={<Switch checked={user.enableGrid} onChange={(e) => dispatch(updateLocalUser({ enableGrid: e.target.checked }))} name="Enable Grid" />}
              label="Enable Grid Strategy (Don't buy if buy price is greater than last sell)" />
          </Grid>

        <div style={styles.spacer} />

        <Grid item>
        <FormControlLabel
              control={<Switch disabled={user.enableLong} checked={user.isMinSellProfitEnabled} onChange={(e) => dispatch(updateLocalUser({ isMinSellProfitEnabled: e.target.checked }))} name="Min Sell Profit" />}
              label="Enable Minimum Sell Profit (Bot will only sell if percentage threshold reached and signals indicate a bearish movement)" />
        </Grid>

        <div style={styles.spacer} />

     { user.isMinSellProfitEnabled && <Grid item>
         <TextField
            disabled={user.enableLong}
            InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            type="number"
            label="Percentage"
            placeholder="Sell Profit Threshold % - a negative acts a dynamic stop loss"
            helperText="Sell Profit Threshold (Only sell if profit is greater than this threshold percentage - can be negative) WARNING: By setting a negative value you accept there may be losses."
            value={user.takeProfitThreshold}
            onChange={(e: any) => dispatch(updateLocalUser({ takeProfitThreshold: e.target.value }))}/>

        </Grid> }
      
        {/* Stop Loss Section */}
        <Grid item>

        <FormControlLabel
            control={<Switch checked={user.isStopLossEnabled} onChange={(e) => dispatch(updateLocalUser({ isStopLossEnabled: e.target.checked }))} name="Stop Loss" />}
            label="Enable Stop Loss (Sell when negative profit reached)" />
        </Grid>

        <div style={styles.spacer} />


        { user.isStopLossEnabled && <Grid item>
            <TextField
            InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            type="number"
            label="Percentage"
            placeholder="Stop Loss Percentage %"
            helperText="Stop Loss Threshold (Sell once negative profit percent is greater than threshold)"
            value={user.stopLossPercentage}
            onChange={(e: any) => dispatch(updateLocalUser({ stopLossPercentage: e.target.value }))}/>
        </Grid> }

    </Card>
  )
}