import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { setUserBegin, updateLocalUser } from "../../redux/actions/userActions"
import { useEffect, useState } from "react"



export const PairSelector = () =>
{
  const dispatch = useDispatch()
  const tradingPairs = useSelector((state: ApplicationState) => state.userStore.user.tradingPairs)
  const tradingPair = useSelector((state: ApplicationState) => state.userStore.user.tradingPair)

  const isMultiTradingEnabled = useSelector((state: ApplicationState) => state.userStore.user.isMultiTradingEnabled)
  const [ pairId, setPairId ] = useState("")

  useEffect(() => {

    // Set the first pair on mount  
    if(!pairId) handleMount()

  }, [tradingPairs])

  const handleMount = () =>
  {
    const pair = tradingPairs?.find(p => p.pair === tradingPair)
    if(pair) setPairId(pair.pairId)
  }

  const handleUpdate = (pairId: string) =>
  {
    setPairId(pairId)
    const pair = tradingPairs?.find(p => p.pairId === pairId)
    dispatch(updateLocalUser({ tradingPair: pair?.pair, fundingCurrency: pair?.base, chartInterval: pair?.interval }))
    dispatch(setUserBegin({ tradingPair: pair?.pair, fundingCurrency: pair?.base, chartInterval: pair?.interval }))
  }

  if(isMultiTradingEnabled && tradingPairs && tradingPairs.length)
  {
    return(
      <Select size="small" sx={{ mr: 2, color: "white" }} value={pairId} onChange={(e: any) => handleUpdate(e.target.value)}>
          {tradingPairs?.map((p, key) => <MenuItem value={p.pairId} key={key}>{p.pair}</MenuItem>)}
        </Select>
    )
  }

  return (<></>)
}