import { Symbol } from "../../interface/Symbol"
import { User } from "../../interface/User"

export enum UserActions
{
  GET_USER_PROFILE_BEGIN = "GET_USER_PROFILE_BEGIN",
  GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR",
  SET_USER_PROFILE_BEGIN = "SET_USER_PROFILE_BEGIN",
  SET_USER_PROFILE_SUCCESS = "SET_USER_PROFILE_SUCCESS",
  SET_USER_PROFILE_ERROR = "SET_USER_PROFILE_ERROR",
  SET_USER_PROFILE_CLEAR = "SET_USER_PROFILE_CLEAR",
  UPDATE_LOCAL_USER = "UPDATE_LOCAL_USER",
  TOGGLE_DRAWER = "TOGGLE_DRAWER",
  GET_SYMBOLS_BEGIN = "GET_SYMBOLS_BEGIN",
  GET_SYMBOLS_SUCCESS = "GET_SYMBOLS_SUCCESS",
  GET_SYMBOLS_ERROR = "GET_SYMBOLS_ERROR"

}

export const getSymbolsBegin = () =>
{
  return {
    type: UserActions.GET_SYMBOLS_BEGIN
  }
} 

export const getSymbolsSuccess = (symbols: Symbol[]) =>
  {
    return {
      type: UserActions.GET_SYMBOLS_SUCCESS,
      data: { symbols }
    }
  } 

  export const getSymbolsError = () =>
    {
      return {
        type: UserActions.GET_SYMBOLS_BEGIN
      }
    } 

export const getUserBegin = () =>
{
  return {
    type: UserActions.GET_USER_PROFILE_BEGIN
  }
}

export const getUserSuccess = (user: User) =>
{
  return {
    type: UserActions.GET_USER_PROFILE_SUCCESS,
    data: { user },
  }
}

export const getUserError = (error: any) =>
{
  return {
    type: UserActions.GET_USER_PROFILE_ERROR,
    error,
  }
}

export const setUserBegin = (updatedUser: User) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_BEGIN,
    data: updatedUser
  }
}

export const setUserSuccess = (user: User) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_SUCCESS,
    data: { user }
  }
}

export const updateLocalUser = (user: User) =>
{
  return {
    type: UserActions.UPDATE_LOCAL_USER,
    data: { user }
  }
}

export const setUserError = (error: any) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_SUCCESS,
    error
  }
}

export const clearUpdateUserProfile = () =>
{
  return {
    type: UserActions.SET_USER_PROFILE_CLEAR,
  }
}

export const toggleDrawer = (isDrawerOpen: boolean) =>
{
  return {
    type: UserActions.TOGGLE_DRAWER,
    data: { isDrawerOpen }
  }
}