import TableContainer from "@mui/material/TableContainer"
import { GridLevel } from "../../../interface/GridBotConfig"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import Paper from "@mui/material/Paper"

interface GridLevelsPreviewProps
{
  gridLevels?: GridLevel[]
}

export const GridLevelsPreview = ({ gridLevels }: GridLevelsPreviewProps) => {

  return (
    <TableContainer style={{ overflowY: "auto", height: "70vh"}} component={Paper} >
    <Table aria-label="simple table" stickyHeader >
      <TableHead>
        <TableRow>
          <TableCell align="right">Buy Price</TableCell>
          <TableCell align="right">Sell Price</TableCell>
          <TableCell align="right">Investment</TableCell>
          <TableCell align="right">Profit</TableCell>
          <TableCell align="center">Percent</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        {gridLevels?.map((row) => (
          <TableRow
            hover
            key={row.gridIndex}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell style={{ fontSize: 12 }} align="right">${row.buyPrice}</TableCell>
            <TableCell style={{ fontSize: 12 }} align="right">${row.sellPrice}</TableCell>
            <TableCell style={{ fontSize: 12 }} align="right">${row.investment.toFixed(2)}</TableCell>
            <TableCell style={{ fontSize: 12, fontWeight: 700  }} align="right">${row.profit.toFixed(2)}</TableCell>
            <TableCell style={{ fontSize: 12, fontWeight: 700 }} align="right">{row.profitPercentage.toFixed(2)}%</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )

}