import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import { Candle } from '../../../interface/Candle';
import { purple } from '@mui/material/colors';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

interface PreviewGridChartProps
{
  candles: Candle[] | undefined;
  chartInterval: string;
  tradingPair: string;
  height?: number
  gridLevels: { buyPrice: number, sellPrice: number }[]
  setInterval: (level: string) => void
}


export const PreviewGridChart = ({ candles, chartInterval, tradingPair, height, gridLevels, setInterval }: PreviewGridChartProps) =>
{
  const closes = candles?.map(c => Number(c.close))
  const buyPrices = gridLevels?.map(c => Number(c.buyPrice))
  const sellPrices = gridLevels?.map(c => Number(c.sellPrice))

  const combined = [ ...closes || [], ...buyPrices || [], ...sellPrices || [] ]

  const dataMin = Math.min.apply(null, combined || [])
  const dataMax = Math.max.apply(null, combined || [])

  return(
    <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Chart Interval: {chartInterval}</Typography>
    <ResponsiveContainer width="100%" height={height || 300}>
    <LineChart
      data={candles || []} >
      <XAxis dataKey="closeTime" tickFormatter={(ts: number) => moment.unix(ts / 1000).format("DD-MMM hh:mmA")} minTickGap={200} />
      <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={[dataMin, dataMax]} />

      <Legend />
       <Line yAxisId="left" type="monotone" dataKey="close" stroke={purple[300]} dot={false} strokeWidth={3}/> 

      <Tooltip 
        labelStyle={{ color: "grey"}} 
        formatter={(tick) => `$${Number(tick).toFixed(8)} USD`} 
        labelFormatter={(ts) => `${moment.unix(ts / 1000).format("DD-MMM hh:mmA")} - ${moment.unix(ts / 1000).fromNow()}`}/>

        {/* Add buy order reference lines */}
        {buyPrices?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`buy-order-${index}`}
              y={order}
              label={{ value: `Buy @ $${order}`, position: "insideTopLeft" }}
              stroke="#2196F3"
              strokeDasharray="3 3"
            />
          ))}

          {/* Add sell order reference lines */}
          {sellPrices?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`sell-order-${index}`}
              y={order}
              label={{ value: `Sell @ $${order}`, position: "insideTopRight" }}
              stroke="#66BB6A"
              strokeDasharray="3 3"
            />
          ))}

    </LineChart>
    </ResponsiveContainer>
    
    <ToggleButtonGroup value={chartInterval} onChange={(_, value) => setInterval(value)} size='small' exclusive sx={{ mb: 1 }}>
          <ToggleButton value="1m">1m</ToggleButton>
          <ToggleButton value="3m">3m</ToggleButton>
          <ToggleButton value="5m">5m</ToggleButton>
          <ToggleButton value="15m">15m</ToggleButton>
          <ToggleButton value="30m">30m</ToggleButton>
          <ToggleButton value="1h">1h</ToggleButton>
          <ToggleButton value="4h">4h</ToggleButton>
          <ToggleButton value="12h">12h</ToggleButton>
          <ToggleButton value="1d">1d</ToggleButton>
          <ToggleButton value="1w">1w</ToggleButton>
          <ToggleButton value="1M">1M</ToggleButton>

        </ToggleButtonGroup>

    </div>

  )
}


const styles = {
  container: {
    border: "1px solid grey",
    borderRadius: 12,
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    textAlign: 'center' as 'center'
  }
}