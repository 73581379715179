import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import { IGridBotConfig } from "../../../interface/GridBotConfig"
import Avatar from "@mui/material/Avatar"
import CardActionArea from "@mui/material/CardActionArea"
import CardActions from "@mui/material/CardActions"
import EditIcon from "@mui/icons-material/Edit"
import Button from "@mui/material/Button"
import RocketLaunch from "@mui/icons-material/RocketLaunch"
import { GREEN } from "../../../Theme/theme"
import moment from "moment"
import Divider from "@mui/material/Divider"
import { useDispatch, useSelector } from "react-redux"
import { getBotConfigs, setCurrentBotConfig, setEditBot } from "../../../redux/reducers/grid/gridReducer"
import { ApplicationState } from "../../../interface/ApplicationState"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"

interface BotConfigItemProps
{
  item: IGridBotConfig
}

export const BotConfigItem = ({ item }: BotConfigItemProps) =>
{
  const dispatch = useDispatch()
  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)

  const getProfit = () => {

    const maxInvest = item.maxInvest
    let realised = 0
    let floating = 0

    for(const sellEvent of item.sellOrderHistory)
    {
      if(sellEvent.isFilled) realised = realised + sellEvent.profit
      if(!sellEvent.isFilled) floating = floating + sellEvent.profit
    }
  
    const percent = maxInvest > 0 ? (realised / maxInvest) * 100 : 0;

    return { realised, floating, percent }
    
  }

  const getColorForProfit = (profit: number) => {
    if(profit > 0) return "success"
    if(profit === 0) return "info"
    if(profit < 0) return "error"
    return "info"
  }

  const isSelected = () =>
  {
    return botConfig?._id === item._id
  }

  const { floating, realised, percent } = getProfit()
  const profitColor = getColorForProfit(realised)
  const profitColorFloating = getColorForProfit(floating)
  const selected = isSelected()

  const handleItemClick = () => {
    dispatch(setCurrentBotConfig(item))
    dispatch(getBotConfigs())
  }

  return (<div style={{ border: selected ? "1px solid grey" : "", borderRadius: selected ? "12px" : ""}}>
    <CardActionArea onClick={handleItemClick} >
    <CardHeader 
      avatar={<Avatar style={{ backgroundColor: item.isEnabled ? GREEN : ""}}><RocketLaunch /></Avatar>}
      title={`${item.name} - ${item.tradingPair}`}
      subheader={`Created ${moment(item.createdAt).fromNow()} | Max Invest $${item.maxInvest}`}>
    </CardHeader>
    </CardActionArea>


    <CardActions>
      <Tooltip title="Once 5 USDT is reached, the bot will sell any remainder profits to USDT">
        <Button size="small" color={profitColor}>Pending Profit ${item?.pendingProfit?.toFixed(2)} </Button>
      </Tooltip>
      
      <Tooltip title="Profit from all trades (fee's have been deducted)">
        <Button size="small" color={profitColor}>Total Profit ${item?.gridProfit?.toFixed(2)} </Button>
      </Tooltip>

      <Tooltip title="Each trades profits added together (should be the same as total profit)">
        <Button size="small" color={profitColor}>Sum ${realised.toFixed(2)} ({percent.toFixed(2)}%)</Button>
      </Tooltip>

      <Tooltip title="The profit if all grid sell limit orders were reached">
        <Button size="small" color={profitColorFloating}>Floating Profit ${floating.toFixed(2)}</Button>
      </Tooltip>

      <Tooltip title="Total times a grid buy was placed and then had the corresponding sale order matched.">
        <Button size="small" color="secondary" >Matched Trades {item.matchedTrades}</Button>
      </Tooltip>

      <Box flex={1} />
      <IconButton size="small" color="default" onClick={() => dispatch(setEditBot(item))} ><EditIcon /></IconButton>

    </CardActions>

    <Divider />
  </div>)
}
