import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketData } from "../../../interface/MarketData";
import { IGridBotConfig } from "../../../interface/GridBotConfig";
import { Candle } from "../../../interface/Candle";

export interface GridStore
{
  tradingPair: string
  isGettingMarkets: boolean
  marketData: MarketData[]
  botConfig?: IGridBotConfig
  showEditBotModal: boolean
  isGettingBotConfigs: boolean,
  botConfigs: IGridBotConfig[]
  lastUpdate?: string;
  candles: Candle[];
  isGettingCandles: boolean
  interval: string
}

const initialState: GridStore = {
  tradingPair: "BTCUSDT",

  // Markets
  isGettingMarkets: false,
  marketData: [],

  // Candles
  candles: [],
  interval: "5m",
  isGettingCandles: false,

  // Current bot config
  botConfig: undefined,
  showEditBotModal: false,
  isGettingBotConfigs: false,
  botConfigs: [],

  // Last update of bot configs and orders
  lastUpdate: undefined,
}

const grid = createSlice({
  initialState,
  name: "Grid",
  reducers: {
    getCandles: (state, action: PayloadAction<{ tradingPair: string, interval: string }>) => {
      state.isGettingCandles = true
      state.interval = action.payload.interval
    },
    getCandlesSuccess: (state, action: PayloadAction<Candle[]>) => {
      state.isGettingCandles = false
      state.candles = action.payload
    },
    getCandlesError: (state) => {
      state.isGettingCandles = false
    },
    getBotConfigs: (state) => {
      state.isGettingBotConfigs = true
    },
    getBotConfigsSuccess: (state, action: PayloadAction<IGridBotConfig[]>) => {
      state.isGettingBotConfigs = false
      state.botConfigs = action.payload
      state.lastUpdate = new Date().toISOString()
    },
    getBotConfigsError: (state) => {
      state.isGettingBotConfigs = false
    },
    closeEditBot: (state) => {
      state.showEditBotModal = false
    },
    setEditBot: (state, action: PayloadAction<IGridBotConfig>) => {
      state.botConfig = action.payload
      state.tradingPair = action.payload.tradingPair
      state.showEditBotModal = true
    },
    clearCurrentBot: (state) => {
      state.botConfig = undefined
    },
    setCurrentBotConfig: (state, action: PayloadAction<IGridBotConfig>) => {
      state.botConfig = action.payload
      state.tradingPair = action.payload.tradingPair
    },
    getMarketData: (state) => {
      state.isGettingMarkets = true
    },
    getMarketDataSuccess: (state, action: PayloadAction<MarketData[]>) => {
      state.isGettingMarkets = false
      state.marketData = action.payload
    },
    getMarketDataError: (state) => {
      state.isGettingMarkets = false
    },
    setMarket: (state, action: PayloadAction<{ tradingPair: string }>) => {
      state.tradingPair = action.payload.tradingPair

      // Clear the bot config if we change market
      state.botConfig = undefined
    }
  },
})


export const { 
  getCandles,
  getCandlesSuccess,
  getCandlesError,
  getBotConfigs,
  getBotConfigsSuccess,
  getBotConfigsError,
  clearCurrentBot,
  closeEditBot, 
  setEditBot, 
  setMarket, 
  getMarketData, 
  getMarketDataSuccess, 
  getMarketDataError, 
  setCurrentBotConfig 
} = grid.actions

export default grid.reducer