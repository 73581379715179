import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment'
import Typography from '@mui/material/Typography';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Candle } from '../../../interface/Candle';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../interface/ApplicationState';
import { purple } from '@mui/material/colors';
import { useState } from 'react';

interface GridChartProps
{
  candles: Candle[] | undefined;
  chartInterval: string;
  tradingPair: string;
  height?: number
  setInterval: (interval: string) => void
}


export const GridChart = ({ candles, chartInterval, tradingPair, height, setInterval }: GridChartProps) =>
{

  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)
  const closes = candles?.map(c => Number(c.close))

  // Grid levels
  const buyOrderPrices = botConfig?.gridLevels?.map(bo => bo.buyPrice)
  const sellOrderPrices = botConfig?.gridLevels?.map(so => so.sellPrice)

  // Not yet filled orders
  const buyOrdersNotFilled = botConfig?.buyOrderHistory?.filter(bo => !bo.isFilled).map(bo => bo.price)
  const sellOrdersNotFilled = botConfig?.sellOrderHistory?.filter(bo => !bo.isFilled).map(so => so.price)

  // Filled orders
  const filledBuyOrders = botConfig?.buyOrderHistory?.filter(bo => bo.isFilled).map(bo => bo.price)
  const filledSellOrders = botConfig?.sellOrderHistory?.filter(bo => bo.isFilled).map(so => so.price)

  const combined = [ ...closes || [], ...buyOrderPrices || [], ...sellOrderPrices || [] ]

  const dataMin = Math.min.apply(null, combined || [])
  const dataMax = Math.max.apply(null, combined || [])

  const [ lineType, setLineType ] = useState<"all" | "pending" | "filled">("all")



  return(
    <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Chart Interval: {chartInterval}</Typography>
    <ResponsiveContainer width="100%" height={height || 300}>
    <LineChart
      data={candles || []} >
      <XAxis dataKey="closeTime" tickFormatter={(ts: number) => moment.unix(ts / 1000).format("DD-MMM hh:mmA")} minTickGap={200} />
      <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={[dataMin, dataMax]} />

      <Legend />
      <Line yAxisId="left" type="monotone" dataKey="close" stroke={purple[300]} dot={false} strokeWidth={3}/>

      <Tooltip 
        labelStyle={{ color: "grey"}} 
        formatter={(tick) => `$${Number(tick).toFixed(8)} USD`} 
        labelFormatter={(ts) => `${moment.unix(ts / 1000).format("DD-MMM hh:mmA")} - ${moment.unix(ts / 1000).fromNow()}`}/>

        {/* all grid */}
        { lineType === "all" && buyOrderPrices?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`buy-order-${index}`}
              y={order}
              label={{ value: `Buy @ $${order}`, position: "insideTopLeft", fontSize: 12 }}
              stroke="#2196F3"
              strokeDasharray="3 3"
            />
        ))}

        {/* Add sell order reference lines */}
        { lineType === "all" && sellOrderPrices?.map((order, index) => (
          <ReferenceLine
            yAxisId="left"
            key={`sell-order-${index}`}
            y={order}
            label={{ value: `Sell @ $${order}`, position: "insideTopRight", fontSize: 12 }}
            stroke="#66BB6A"
            strokeDasharray="3 3"
          />
        ))}

        {/* Pending buy order reference lines */}
        { lineType === "pending" && buyOrdersNotFilled?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`buy-order-${index}`}
              y={order}
              label={{ value: `Buy @ $${order}`, position: "insideTopLeft", fontSize: 12 }}
              stroke="#2196F3"
              strokeDasharray="3 3"
            />
          ))}

        {/* Pending sell order reference lines */}
        { lineType === "pending" && sellOrdersNotFilled?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`sell-order-${index}`}
              y={order}
              label={{ value: `Sell @ $${order}`, position: "insideTopRight", fontSize: 12 }}
              stroke="#66BB6A"
              strokeDasharray="3 3"
            />
          ))}

        {/* filled buy order reference lines */}
        { lineType === "filled" && filledBuyOrders?.map((order, index) => (
            <ReferenceLine
              yAxisId="left"
              key={`buy-order-${index}`}
              y={order}
              
              label={{ value: `Bought @ $${order}`, position: "insideTopLeft", fontSize: 12 }}
              stroke="#2196F3"
              strokeDasharray="3 3"
            />
          ))}

        {/* filled sell order reference lines */}
        { lineType === "filled" && filledSellOrders?.map((order, index) => (
          <ReferenceLine
            yAxisId="left"
            key={`sell-order-${index}`}
            y={order}
            label={{ value: `Sold @ $${order}`, position: "insideTopRight", fontSize: 12 }}
            stroke="#66BB6A"
            strokeDasharray="3 3"
          />
        ))}

    </LineChart>
    </ResponsiveContainer>
        
        <ToggleButtonGroup value={chartInterval} onChange={(_, value) => setInterval(value)} size='small' exclusive sx={{ mr: 1 }}>
          <ToggleButton value="1m">1m</ToggleButton>
          <ToggleButton value="3m">3m</ToggleButton>
          <ToggleButton value="5m">5m</ToggleButton>
          <ToggleButton value="15m">15m</ToggleButton>
          <ToggleButton value="30m">30m</ToggleButton>
          <ToggleButton value="1h">1h</ToggleButton>
          <ToggleButton value="4h">4h</ToggleButton>
          <ToggleButton value="12h">12h</ToggleButton>
          <ToggleButton value="1d">1d</ToggleButton>
          <ToggleButton value="1w">1w</ToggleButton>
          <ToggleButton value="1M">1M</ToggleButton>
        </ToggleButtonGroup>

        { botConfig && <ToggleButtonGroup value={lineType} onChange={(_, value) => setLineType(value)} size='small' exclusive>
          <ToggleButton value="pending">Pending</ToggleButton>
          <ToggleButton value="filled">Filled</ToggleButton>
          <ToggleButton value="all">All</ToggleButton>
        </ToggleButtonGroup> }


    </div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    margin: 8,
    fontSize: 10,
    textAlign: 'center' as 'center'
  }
}