import { IBuyGridOrder } from "../../../interface/GridBotConfig"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import { GenericTablet } from "../../signalStats/GenericTablet";
import { purple, yellow } from "@mui/material/colors";

interface BuyTableProps
{
  orders: IBuyGridOrder[]
}

export const BuyTable = ({ orders } : BuyTableProps) => {

  return (
    <TableContainer style={{ overflow: "auto", height: "35vh"}}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader >
      <TableHead>
        <TableRow>
          <TableCell align="right">Price</TableCell>
          <TableCell align="right">Qty</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="center">Order Status</TableCell>
          <TableCell align="right">Last Updated</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((row) => (
          <TableRow
            hover
            key={row.orderId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell align="right">${row.price}</TableCell>
            <TableCell align="right">{row.qty || "-"}</TableCell>
            <TableCell align="right">{row.qty ? `$${(row.price * row.qty).toFixed(2)}` : "-"}</TableCell>

            <TableCell align="right">{row.isFilled ? <GenericTablet text="Filled" color={yellow[800]} /> : <GenericTablet text="Pending" color={purple[400]} />}</TableCell>
            <TableCell align="right">{moment(row.timestamp).fromNow()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}