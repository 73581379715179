

export const contentStyle = 
{
  backgroundColor: "#fff",
  borderRadius: "12px",
  border: "1px solid #ccc",
  padding: "16px",
  fontSize: "16px", // Larger text
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
}

export const itemStyle = {
  fontSize: "14px",
}