import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab';
import Save from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';

import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../interface/ApplicationState';
import { clearUpdateUserProfile, getUserBegin, setUserBegin } from '../redux/actions/userActions';

import { User } from '../interface/User';
import { Indicators } from '../components/Indicators';
import { useEffect } from 'react';
import { TradeSettings } from './settings/TradeSettings';
import { LossPrevention } from './settings/LossPrevention';
import { ProfitConfiguration } from './settings/ProfitConfiguration';
import { BinanceConnectionSettings } from './settings/BinanceConnectionSettings';
import { CandleStickSettings } from './settings/CandleStickSettings';
import { SignalsSection } from './signals/SignalsSection';
import { SubscriptionSection } from '../components/subscriptions/SubscriptionSection';
import { TradingPair } from '../interface/TradingPair';

export const Settings = () =>
{

  const dispatch = useDispatch()
  const isGettingUserProfile = useSelector((state: ApplicationState) => state.userStore.isGettingUserProfile)
  const isSettingUserProfile = useSelector((state: ApplicationState) => state.userStore.isSettingUserProfile)
  const updateProfileResult = useSelector((state: ApplicationState) => state.userStore.updateProfileResult)

  const user = useSelector((state: ApplicationState) => state.userStore.user)

  const isAtLeastOneIndicatorEnabled = () =>
  {
    if(user.isDynamicEnabled) return true
    if(user.macdEnabled) return true
    if(user.rsiEnabled) return true
    if(user.candleStickPatternEnabled) return true
    if(user.emaEnabled) return true
    if(user.tripleEmaEnabled) return true
    if(user.mfiEnabled) return true
    if(user.customEnabled) return true
    if(user.smaEnabled) return true
    if(user.rsiCrossOverEnabled) return true
    if(user.isTripleRSIEnabled) return true
    if(user.isVolumeProfileEnabled) return true
    if(user.isVolumeCrossoverEnabled) return true
    if(user.isObvEnabled) return true
    return false
  }

  const isTradingPairsValid = () =>
  {
    if(!user.tradingPairs) return true
    const dups: TradingPair[] = []
    for(const pair of user.tradingPairs)
    {
      if(!pair.base || !pair.maxInvest || !pair.pair) return false
      if(dups.find(p => p.pair === pair.pair)) return false
      dups.push(pair);
    }

    return true
  }

  const updateUser = () =>
  {
    const updatedUser: User = {
      tradingEnabled: user.tradingEnabled,
      chartInterval: user.chartInterval,
      binanceApiKey: user.binanceApiKey,
      binanceApiSecret: user.binanceApiSecret,
      maxBuy: user.maxBuy,
      maxSell: user.maxSell,
      tradingPair: user.tradingPair,
      fundingCurrency: user.fundingCurrency,
      enableLong: user.enableLong,
      macdBuyThreshold: user.macdBuyThreshold,
      macdSellThreshold: user.macdSellThreshold,
      macdEnabled: user.macdEnabled,
      candleStickPatternEnabled: user.candleStickPatternEnabled,
      rsiEnabled: user.rsiEnabled,
      rsiThresholdBuy: user.rsiThresholdBuy,
      rsiThresholdSell: user.rsiThresholdSell,
      takeProfitThreshold: user.takeProfitThreshold,
      isMinSellProfitEnabled: user.isMinSellProfitEnabled,
      emaEnabled: user.emaEnabled,
      takeProfitPercentage: user.takeProfitPercentage,
      isTakeProfitEnabled: user.isTakeProfitEnabled,
      mfiEnabled: user.mfiEnabled,
      mfiThresholdBuy: user.mfiThresholdBuy,
      mfiThresholdSell: user.mfiThresholdSell,
      customEnabled: user.customEnabled,
      smaEnabled: user.smaEnabled,
      emaShortPeriod: user.emaShortPeriod,
      emaLongPeriod: user.emaLongPeriod,
      emaType: user.emaType,
      tripleEmaEnabled: user.tripleEmaEnabled,
      tripleEmaShortPeriod: user.tripleEmaShortPeriod,
      tripleEmaMediumPeriod: user.tripleEmaMediumPeriod,
      tripleEmaLongPeriod: user.tripleEmaLongPeriod,
      sellProfitsThreshold: user.sellProfitsThreshold,
      sellProfitsToFiat: user.sellProfitsToFiat,
      isStopLossEnabled: user.isStopLossEnabled,
      stopLossPercentage: user.stopLossPercentage,
      isSignalsEnabled: user.isSignalsEnabled,
      signalsInvestPercent: user.signalsInvestPercent,
      signalsInvestSources: user.signalsInvestSources,
      signalsMaxBuy: user.signalsMaxBuy,
      isAdxEnabled: user.isAdxEnabled,
      adxTrendLimit: user.adxTrendLimit,
      isBuyBackInDelayEnabled: user.isBuyBackInDelayEnabled,
      buyBackInDelay: user.buyBackInDelay,
      isDynamicEnabled: user.isDynamicEnabled,
      switchToTrendingIndicatorThreshold: user.switchToTrendingIndicatorThreshold,
      currentIndicator: user.currentIndicator,
      preferMarket: user.preferMarket,
      rsiCrossOverEnabled: user.rsiCrossOverEnabled,
      rsiShort: user.rsiShort,
      rsiMedium: user.rsiMedium,
      rsiLong: user.rsiLong,
      binanceApiEndpoint: user.binanceApiEndpoint,
      isTripleRSIEnabled: user.isTripleRSIEnabled,
      tripleRsi1: user.tripleRsi1,
      tripleRsi2: user.tripleRsi2,
      tripleRsi3: user.tripleRsi3,
      tradingPairs: user.tradingPairs,
      isMultiTradingEnabled: user.isMultiTradingEnabled,
      isVolumeProfileEnabled: user.isVolumeProfileEnabled,
      vpNumberOfBars: user.vpNumberOfBars,
      isVolumeCrossoverEnabled: user.isVolumeCrossoverEnabled,
      shortVolumeCrossover: user.shortVolumeCrossover,
      longVolumeCrossover: user.longVolumeCrossover,
      enableGrid: user.enableGrid,
      isObvEnabled: user.isObvEnabled,
      obvShortPeriod: user.obvShortPeriod,
      obvLongPeriod: user.obvLongPeriod,
      buyOnlyIfChangePositive: user.buyOnlyIfChangePositive
    }

    dispatch(setUserBegin(updatedUser))
  }

  useEffect(() => {

    dispatch(getUserBegin())

  }, [])

  return(
    <div>
      <div style={styles.container}>

      <CssBaseline />
      <Grid container spacing={3} direction="column">

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Subscription</Typography>
      </Grid>

      <SubscriptionSection />

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Binance Connection Settings</Typography>
      </Grid>

      <BinanceConnectionSettings />

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Trading Settings</Typography>
      </Grid>

      <TradeSettings />

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Loss Prevention</Typography>
      </Grid>

      <LossPrevention />

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Profit Configuration</Typography>
      </Grid>

      <ProfitConfiguration />

      <Grid item>
        <Typography variant="h6" color="textSecondary" style={styles.heading}>Signals</Typography>
        <Typography color="textSecondary" variant="subtitle2">Use telegram signals</Typography>
      </Grid>

      <SignalsSection />

      <Grid item>
        <Indicators />
      </Grid>

      { !user.isMultiTradingEnabled && <Grid item lg={3} md={12} xs={12}>
      <Typography variant="h6">Candle stick chart interval</Typography>
       <CandleStickSettings />
    </Grid> }

  </Grid>

 </div>

    <Fab disabled={isGettingUserProfile || isSettingUserProfile || !isAtLeastOneIndicatorEnabled() || !isTradingPairsValid() } color="primary" aria-label="save" style={styles.fab} onClick={updateUser}>
    { isGettingUserProfile || isSettingUserProfile ? <CircularProgress /> : <Save /> }
    </Fab>

    <Snackbar open={!!updateProfileResult} onClose={() => dispatch(clearUpdateUserProfile())} autoHideDuration={3000}>
        <Alert severity="success" onClose={() => dispatch(clearUpdateUserProfile())}>
        {updateProfileResult}
      </Alert>
    </Snackbar>
  </div>
  )
}

export const styles = {
  container: {
    margin: 16,
    padding: 16,
    overflow: "auto",
    height: "90vh"
  },
  slider: {
    marginTop: 30,
  },
  heading: {
    fontWeight: "600"
  },
  fab: {
    position: "absolute" as "absolute",
    right: 0,
    bottom: 0,
    margin: 64
  },
  spacer: {
    marginBottom: 12,
  },
  card: {
    padding: 32,
    marginTop: 16,
    marginRight: 32,
    width: 300,
  },
  formLabel: {
    marginBottom: 12
  }
}