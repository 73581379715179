import { getSymbolsError, getSymbolsSuccess, getUserError, getUserSuccess, setUserError, setUserSuccess, UserActions } from "../actions/userActions";
import { takeLatest, put } from 'redux-saga/effects'
import { UserApi } from "../../api/UserApi";
import { Symbol } from "../../interface/Symbol";

function* getUserProfile(): any
{
  const user = yield new UserApi().getSettings()
  try
  {
    if(user)
    {
      yield put(getUserSuccess(user))
    }
  }
  catch(error)
  {
    yield put(getUserError(error))
  }

}

function* setUserProfile(action: any): any
{
  try
  {
    const user = yield new UserApi().setSettings(action.data)
    yield put(setUserSuccess(user))
  }
  catch(error)
  {
    yield put(setUserError(error))
  }

}

function* getSymbolsWorker(): any
{
  try
  {
    const symbols = yield new UserApi().getSymbols()

    const filtered = symbols?.filter((item: Symbol) => 
        item.symbol.endsWith("USDT") || 
        item.symbol.endsWith("TUSD") ||
        item.symbol.endsWith("BUSD") )

    yield put(getSymbolsSuccess(filtered))
  }
  catch(error)
  {
    yield put(getSymbolsError())
  }

}


export function* userSaga()
{
  yield takeLatest(UserActions.GET_USER_PROFILE_BEGIN, getUserProfile)
  yield takeLatest(UserActions.SET_USER_PROFILE_BEGIN, setUserProfile)
  yield takeLatest(UserActions.GET_SYMBOLS_BEGIN, getSymbolsWorker)
}