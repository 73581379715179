import { MarketData } from "../../../interface/MarketData"
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { blue, green, red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../interface/ApplicationState";
import { setMarket } from "../../../redux/reducers/grid/gridReducer";


interface MarketListItemProps
{
  item: MarketData
}

export const MarketListItem = ({ item }: MarketListItemProps) => {

  const dispatch = useDispatch()
  const tradingPair = useSelector((state: ApplicationState) => state.grid.tradingPair)

  const getColor = () => {
    if(Number(item.priceChangePercent)  > 0) return green[500]
    if(Number(item.priceChangePercent)  < 0) return red[500]
    if(Number(item.priceChangePercent) === 0) return blue[400]
  }

  const bgColor = getColor()

  return (
    <ListItem
    disablePadding >
    <ListItemButton role={undefined} onClick={() => dispatch(setMarket({ tradingPair: item.symbol }))} dense selected={item.symbol === tradingPair}>
      <Avatar style={{ ...styles.avatar, backgroundColor: bgColor }} >{Number(item.priceChangePercent).toFixed(2)}%</Avatar>
      <ListItemText id={item.symbol} primary={item.symbol} secondary={`Price: ${item.lastPrice} `} />

    </ListItemButton>
  </ListItem>
  )

}

const styles = {
  avatar: {
    marginRight: 12,
    fontSize: 10,
    fontWeight: "700",
    color: "white"
  }
}