import { useEffect, useState } from "react"

// MUI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import CircularProgress from "@mui/material/CircularProgress"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"


// Icons
import SearchIcon from '@mui/icons-material/Search'
import CloudIcon from '@mui/icons-material/CloudDone'
import CloseIcon from '@mui/icons-material/Close'

import TestIcon from '@mui/icons-material/GraphicEq'
import ClearIcon from '@mui/icons-material/Clear'

// Custom 
import { PairListItem } from "./PairListItem"
import { Indicators } from "../../Indicators"
import { LottieLoading } from "../../placeholder/LottieLoading"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { clearSettings, setBacktestConfig, setBacktestSettings } from "../../../redux/reducers/backtesting/backtestingReducer"
import { ApplicationState } from "../../../interface/ApplicationState"
import { TakeProfitAndStopLossConfig } from "./TakeProfitAndStopLossConfig"
import { ScheduleConfig } from "./ScheduleConfig"
import { ChartIntervalConfig } from "./ChartIntervalConfig"
import { useFilter } from "./hooks/useFilter"
import { useSaveBacktest } from "./hooks/useSaveBacktest"
import { BuySellLimit } from "./BuySellLimit"
import { getSymbolsBegin } from "../../../redux/actions/userActions"

interface CreateCustomBackTestProps
{
  open: boolean
  onClose: () => void
}

export const CreateCustomBackTest = ({ open, onClose }: CreateCustomBackTestProps) => {

  const { addResult, handleSave, saving, setAddResult } = useSaveBacktest()
  const [ query, setQuery ] = useState("")
  const symbols = useSelector((state: ApplicationState) => state.userStore.symbols)
  const isGettingSymbols = useSelector((state: ApplicationState) => state.userStore.isGettingSymbols)

  const dispatch = useDispatch()
  const config = useSelector((state: ApplicationState) => state.backtesting.config)
  const settings = useSelector((state: ApplicationState) => state.backtesting)
  const filteredSymbols = useFilter(query, symbols)

  useEffect(() => {
    if(!symbols || symbols.length === 0) dispatch(getSymbolsBegin())
  }, [])

  useEffect(() => {
    if(addResult && addResult.color === "success") setTimeout(() => handleClose(), 3000)
  }, [ addResult ])

  const handleClearSearch = () => {
    setQuery("")
  }
  
  const handleSelectPair = (pair: string) => {
    setQuery("")
    dispatch(setBacktestConfig({ tradingPair: pair }))
    dispatch(setBacktestSettings({ isEnabled: true }))
  } 

  const handleClose = () => {
    dispatch(clearSettings())
    onClose()
  }

  const canSave = () => {
    if(!settings.accountBalanceUsdt || !settings.friendlyName || !settings.schedule || !settings.config?.tradingPair || !settings.config.chartInterval) return false
    return true
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" spacing={2}> 
          <Grid item >
            <TestIcon style={styles.testTube} />
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h6">Create Custom Backtest</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}><CloseIcon style={styles.testTube} /></IconButton>
          </Grid>
        </Grid>
        </DialogTitle>

      { isGettingSymbols && <LottieLoading animation={require("../../../animations/backtesting-lottie.json")} height={150} width={150} loop text="Getting Trading Pairs" containerHeight="20vh"/>}

     { !isGettingSymbols && <DialogContent>

        <Grid container direction="column" spacing={2}>

          { !config?.tradingPair && <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={700}>Search for a pair</Typography>
              <TextField 
                 value={query}
                 onChange={(e: any) => setQuery(e.target.value)}
                 InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1}} />,
                  endAdornment: <IconButton onClick={handleClearSearch}><ClearIcon /></IconButton>
                 }}
                 autoFocus 
                 fullWidth 
                 placeholder="Search for a trading pair to begin" />
                { query && <Typography variant="caption">{`Found ${filteredSymbols.length} trading pairs`}</Typography> }
            </Grid>

            <Grid item>
              <List style={styles.list}>
                { filteredSymbols.map((item, key) => <PairListItem key={key} item={item} onClick={handleSelectPair} />)}
              </List>
            </Grid></> }


            { config?.tradingPair && <>

            <Grid item>
              <Typography sx={{ mb: 1 }} fontWeight="700">Test Description</Typography>
              <TextField 
                fullWidth 
                placeholder="Custom backtest name - E.G My first backtest" 
                value={settings.friendlyName} 
                onChange={(e: any) => dispatch(setBacktestSettings({ friendlyName: e.target.value }))}/>
            </Grid>
          
            <Grid item>
              <PairListItem onEdit={() => dispatch(setBacktestConfig({ tradingPair: ""}))} isSelected onClick={() => {}} item={{ symbol: config?.tradingPair, baseAsset: "", quoteAsset: "", status: "Select Pair" } } />
            </Grid>

            <Grid item>
            <Typography sx={{ mb: 1 }} fontWeight="700">Notify Config</Typography>
              <FormControlLabel value={settings?.notifyAfterComplete} label="Send push message after test is complete" onChange={(_, value) => dispatch(setBacktestSettings({ notifyAfterComplete: value }))} control={<Switch />} />
            </Grid>

            <BuySellLimit />

            <ChartIntervalConfig />
            
            <ScheduleConfig />
          
            <TakeProfitAndStopLossConfig />

            <Grid item>
              <Indicators isBacktest />
            </Grid>
        
            </>}

        </Grid>

      </DialogContent> }

      <DialogActions>
        { !isGettingSymbols && <Button sx={{ m: 3 }} variant="contained" fullWidth size="large" onClick={() => handleSave(settings)} disabled={saving || !canSave()} startIcon={saving ? <CircularProgress size={16} /> : <CloudIcon />}>{ saving ? "Saving" : "Save" }</Button> }
      </DialogActions>

      { addResult && <Snackbar open autoHideDuration={5000} onClose={() => setAddResult(undefined)} >
        <Alert variant="filled" severity={addResult.color}>{addResult?.message}</Alert>
      </Snackbar> }

    </Dialog>
  )
}

export const styles = {
  list: {
    overflow: "auto" as "auto",
    height: 300
  },
  testTube: {
    marginTop: 2,
  },
  card: {
    padding: 6,
  },
  switchItem: {
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 6,
  }
}