import Card from '@mui/material/Card'
import { User } from '../../interface/User'
import { AVATAR_SIZE, styles } from '../../Pages/Dashboard'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'
import CardActionArea from '@mui/material/CardActionArea'
import Divider from '@mui/material/Divider'
import CryptoIcon from "@mui/icons-material/DonutLarge"
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { BalanceItem, Summary } from '../../interface/Summary'
import { getBalanceForAsset } from '../../utils/getBalanceForAsset'
import { DEFAULT_FUNDING_CURRENCY } from '../../const'
import { useState } from 'react'
import { SellModal } from '../modals/SellModal/SellModal'

interface CurrentFundsProps
{
  user: User
  loading: boolean
  summary: Summary | undefined
}

export const CurrentFunds = ({ user, loading, summary }: CurrentFundsProps) =>
{
  const [ showSellModalWithPair, setShowSellModalPair ] = useState("")
  const [ freeAmount, setFreeAmount ] = useState(0)

  const getMaxSell = () =>
  {
    try
    {
      return freeAmount * Number(summary?.prices[showSellModalWithPair])
    }
    catch(error)
    {
      return 0
    }
  }

  const handleShowSellModal = (balanceItem: BalanceItem) =>
  { 
    if(!balanceItem.asset.startsWith("USDT"))
    {
      setShowSellModalPair(balanceItem.asset + "USDT")
      setFreeAmount(Number(balanceItem.free))
    }
  }

  return(
    <Card style={styles.card}>
    { !loading ? <Typography fontWeight={700} color="textSecondary">Current Funds ({user.fundingCurrency || DEFAULT_FUNDING_CURRENCY})</Typography> : <Skeleton width={200} /> }
    <Divider sx={{ mb: 1 }} />
    <div style={{ display: 'flex', flexDirection: "row" }}>
      <div style={{ display: 'flex', flexDirection: "column", flex: 1}}>
       { !loading ? <Typography color="textSecondary" variant="caption">Total Assets ${summary && summary?.totalAssets.toFixed(2)} {user.fundingCurrency}</Typography> : <Skeleton width={150} />}

      {/* Assets Scroller */}
      <div style={localStyles.assets}>
       { !loading && summary && summary.account && summary.account.balances.map((item, key) => {
         if(Number(item.free) > 0) return (
          <CardActionArea key={key} onClick={() => handleShowSellModal(item)}>
             <Card style={localStyles.cardItem}>
                <CardHeader avatar={<CryptoIcon />} title={item.asset} subheader={item.free} />
            </Card>
            </CardActionArea>

         )
       })}
      </div>

      </div>

      {loading ? <Skeleton style={styles.loading} variant="circular" width={AVATAR_SIZE} height={AVATAR_SIZE} /> : 
      <Tooltip title={`Current funds in ${user.fundingCurrency}`}>
      <Avatar style={styles.avatar1}>${Number(getBalanceForAsset(summary, user.fundingCurrency || DEFAULT_FUNDING_CURRENCY)?.free).toFixed(2)}</Avatar></Tooltip>}

    </div>

      { !!showSellModalWithPair && <SellModal waitForBearSignal={false} tradingPair={showSellModalWithPair} open={!!showSellModalWithPair} handleClose={() => setShowSellModalPair("")} maxSell={getMaxSell()} /> }

  </Card>
  )
}

const localStyles = {
  cardItem: {
    padding: 3,
    paddingLeft: 10,
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    marginTop: 3,
    marginBottom: 3,
    width: 180
  },
  assets: {
    overflowY: 'auto' as 'auto', 
    display: 'flex' as 'flex', 
    flexDirection: "column" as 'column', 
    height: 140
  }
 
}